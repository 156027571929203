import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import './layout.css';

// initializing the fontawesome library and icons
library.add(fas, fal);

// https://ogp.me/
type OpenGraphType =
  | 'music.song'
  | 'music.album'
  | 'music.playlist'
  | 'music.radio_station'
  | 'video.movie'
  | 'video.episode'
  | 'video.tv_show'
  | 'video.other'
  | 'article'
  | 'book'
  | 'profile'
  | 'website';

interface Props {
  title?: string;
  description?: string;
  keywords?: string;
  heroImage?: string;
  heroImageAlt?: string;
  ogType?: OpenGraphType;
  children?: React.ReactNode;
}

type Meta = Array<{ name: string; content: string }>;

const usePageTitle = (...components: (string | undefined)[]) => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return [...components, siteTitle]
    .filter((component) => !!component)
    .join(' — ');
};

const buildMeta = ({
  title,
  description,
  keywords,
  heroImage,
  heroImageAlt,
  ogType,
}: Props): Meta => {
  const meta: Meta = [];
  meta.push({ name: 'twitter:site', content: '@teamexos' });
  if (ogType) {
    meta.push({ name: 'og:type', content: ogType });
  }
  if (title) {
    meta.push({ name: 'og:title', content: title });
    meta.push({ name: 'twitter:title', content: title });
  }
  if (description) {
    meta.push({ name: 'description', content: description });
    meta.push({ name: 'og:description', content: description });
    meta.push({ name: 'twitter:description', content: description });
  }
  if (keywords) {
    meta.push({ name: 'keywords', content: keywords });
  }
  if (heroImage) {
    meta.push({ name: 'twitter:card', content: 'summary_large_image' });
    meta.push({ name: 'og:image', content: heroImage });
    meta.push({ name: 'twitter:image', content: heroImage });
    if (heroImageAlt) {
      meta.push({ name: 'og:image:alt', content: heroImageAlt });
      meta.push({ name: 'twitter:image:alt', content: heroImageAlt });
    }
  }
  return meta;
};

const Layout: React.FC<Props> = ({ children, ...props }) => {
  const pageTitle = usePageTitle(props.title);
  const meta = buildMeta(props);

  return (
    <>
      <Helmet defer={false} title={pageTitle}>
        <meta charSet="utf-8" />
        {meta.map(({ name, content }) => (
          <meta name={name} content={content} key={name} />
        ))}
        <html lang="en" />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
