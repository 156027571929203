import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: Record<string, unknown>;
  LocalDate: string;
  LocalDateTime: string;
  LocalTime: string;
  ZoneId: string;
  ZonedDateTime: string;
};

export type ActiveCustomPlanDay = {
  __typename?: 'ActiveCustomPlanDay';
  date: Scalars['LocalDate'];
  itemCompletedCount: Scalars['Int'];
  itemCount: Scalars['Int'];
};

export type Activity = {
  date: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  program?: Maybe<Program>;
  progress: Scalars['Float'];
  title: Scalars['String'];
  trendType?: Maybe<ActivityTrendType>;
  type: ActivityType;
};

export type ActivityAggregates = {
  __typename?: 'ActivityAggregates';
  /** count of activities completed excluding sleep */
  activities: Scalars['Int'];
  /** selected date range */
  date?: Maybe<ZonedDateTimeRange>;
  /** count of days that had at least 1 activity */
  days: Scalars['Int'];
  /** count of real activity minutes excluding sleep */
  minutes: Scalars['Int'];
  minutesAsleepPerDayAverage: Scalars['Int'];
  restingHeartRatePerDayAverage: Scalars['Int'];
};

export type ActivityAttribution = AssignedPractice | AssignedProgram;

export type ActivityConnection = Connection & {
  __typename?: 'ActivityConnection';
  count: Scalars['Int'];
  edges: Array<ActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ActivityEdge = Edge & {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String'];
  node: Activity;
};

export type ActivityFilter = {
  includeIncomplete?: InputMaybe<Scalars['Boolean']>;
  trendType?: InputMaybe<Array<InputMaybe<ActivityTrendType>>>;
  type?: InputMaybe<Array<InputMaybe<ActivityType>>>;
};

export type ActivityGenre = {
  __typename?: 'ActivityGenre';
  canBeAddedManually: Scalars['Boolean'];
  canHaveDistance: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ActivityGenresFilter = {
  canBeAddedManually?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityPayload = {
  __typename?: 'ActivityPayload';
  activity: Activity;
  user: User;
};

export type ActivitySource =
  | Article
  | Audio
  | MboClass
  | PhysicalActivity
  | Survey
  | Video
  | WearableActivity;

export enum ActivityTrendType {
  Classes = 'classes',
  ManualEntry = 'manual_entry',
  Practice = 'practice',
  Program = 'program',
  Sleep = 'sleep',
  /** @deprecated training plans have been retired */
  TrainingPlan = 'training_plan',
  Wearables = 'wearables',
}

export enum ActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  Virtual = 'Virtual',
  Vod = 'Vod',
  /** @deprecated use wearable sdk specific type */
  Wearable = 'Wearable',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthConnectSleep = 'WearableHealthConnectSleep',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type AddReactionInput = {
  kind: ReactionKind;
  messageId: Scalars['ID'];
};

export type AddUserToStudioPayload = {
  __typename?: 'AddUserToStudioPayload';
  studio: Studio;
  user: User;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  addressLine4?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  localityOrCity?: Maybe<Scalars['String']>;
  postalOrZipCode?: Maybe<Scalars['String']>;
  stateOrDistrictOrProvince?: Maybe<Scalars['String']>;
};

export type AdminBackfillAssessmentAttributionTypeInput = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type AdminChangeAuthProviderInput = {
  id: Scalars['ID'];
  idpUsername: Scalars['String'];
};

export type AdminUpdateUserInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']>;
  id: Scalars['ID'];
  setTestPassword?: InputMaybe<Scalars['Boolean']>;
};

export type Answer = EnumAnswer | NumberAnswer | PhotoAnswer | StringAnswer;

export type AnswerSurveyInput = {
  answers: Array<SurveyResponseInput>;
  assessmentId?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  surveyIdentifier: Scalars['String'];
  surveyName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum App {
  Heart = 'heart',
  Prince = 'prince',
}

export type ArchiveCoachTouchPointPayload = {
  __typename?: 'ArchiveCoachTouchPointPayload';
  coachTouchPoint: CoachTouchPoint;
};

export type Article = Content &
  Node & {
    __typename?: 'Article';
    author?: Maybe<Scalars['String']>;
    brand?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    equipment: Array<Scalars['String']>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    /** @deprecated use contentGenre */
    formattedGenre?: Maybe<Scalars['String']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    intensity?: Maybe<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    kind?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    mediaType?: Maybe<Scalars['String']>;
    mnmrType?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    readingTime?: Maybe<Scalars['String']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    shortDescription?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type ArticleRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ArticleActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'ArticleActivity';
    brand?: Maybe<Scalars['String']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']>;
    coachLastName?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    source?: Maybe<Article>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type ArticlesConnection = Connection & {
  __typename?: 'ArticlesConnection';
  count: Scalars['Int'];
  edges: Array<ArticlesEdge>;
  nodes: Array<Article>;
  pageInfo: PageInfo;
};

export type ArticlesEdge = Edge & {
  __typename?: 'ArticlesEdge';
  cursor: Scalars['String'];
  node: Article;
};

export type AssessmentAssignment = Node & {
  __typename?: 'AssessmentAssignment';
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  survey?: Maybe<Survey>;
  surveyContent: InAppSurvey;
  template: SurveyTemplate;
};

export type AssessmentSurvey = Node & {
  __typename?: 'AssessmentSurvey';
  id: Scalars['ID'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   */
  isMemberFacing: Scalars['Boolean'];
  mnmrType?: Maybe<Scalars['String']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']>;
};

export type AssessmentSurveyEdge = Edge & {
  __typename?: 'AssessmentSurveyEdge';
  cursor: Scalars['String'];
  node: AssessmentSurvey;
};

export type AssessmentSurveysConnection = Connection & {
  __typename?: 'AssessmentSurveysConnection';
  count: Scalars['Int'];
  edges: Array<AssessmentSurveyEdge>;
  nodes: Array<AssessmentSurvey>;
  pageInfo: PageInfo;
};

export type AssignAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']>;
  /** If true, will allow admin users in the dev environment to override assignment date */
  isTest?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['ID'];
  surveyContentIds: Array<Scalars['ID']>;
};

export type AssignAssessmentsPayload = {
  __typename?: 'AssignAssessmentsPayload';
  assessments: Array<AssessmentAssignment>;
};

export type AssignFitCoachPayload = {
  __typename?: 'AssignFitCoachPayload';
  coach: User;
  user: User;
};

export type AssignPracticePayload = {
  __typename?: 'AssignPracticePayload';
  assignedPractice: AssignedPractice;
};

export type AssignProgramPayload = {
  __typename?: 'AssignProgramPayload';
  assignedProgram: AssignedProgram;
};

export type AssignedPractice = {
  __typename?: 'AssignedPractice';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  completionNotified: Scalars['Boolean'];
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  isUnenrolled: Scalars['Boolean'];
  practiceProgram?: Maybe<PracticeProgram>;
  user: User;
};

export type AssignedProgram = Node & {
  __typename?: 'AssignedProgram';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  isUnenrolled: Scalars['Boolean'];
  program?: Maybe<Program>;
  user: User;
};

export type Audio = Content &
  Node & {
    __typename?: 'Audio';
    audio: Scalars['String'];
    author?: Maybe<Scalars['String']>;
    brand?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    equipment: Array<Scalars['String']>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    /** @deprecated use contentGenre */
    formattedGenre?: Maybe<Scalars['String']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    intensity?: Maybe<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    kind?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    readingTime?: Maybe<Scalars['String']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']>;
    subGenre?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    /** @deprecated No longer supported */
    thumbnail?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
    transcript?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    url?: Maybe<Scalars['String']>;
  };

export type AudioRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type AudioActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'AudioActivity';
    brand?: Maybe<Scalars['String']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']>;
    coachLastName?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    source?: Maybe<Audio>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  action: Scalars['String'];
  createdAt: Scalars['ZonedDateTime'];
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  principal: Principal;
};

export type AuditLogEdge = Edge & {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String'];
  node: AuditLog;
};

export type AuditLogsConnection = Connection & {
  __typename?: 'AuditLogsConnection';
  count: Scalars['Int'];
  edges: Array<AuditLogEdge>;
  nodes: Array<AuditLog>;
  pageInfo: PageInfo;
};

export type BlockUserInput = {
  isBlocked: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type BlockUserPayload = {
  __typename?: 'BlockUserPayload';
  user?: Maybe<User>;
};

export type BookClassInput = {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  mboClassId: Scalars['Int'];
  mboStudioId: Scalars['String'];
  userId: Scalars['String'];
};

export type BookClassPayload = {
  __typename?: 'BookClassPayload';
  mboClass: MboClass;
};

export type BookSessionInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  calendlyId?: InputMaybe<Scalars['ID']>;
  chatRoomId?: InputMaybe<Scalars['ID']>;
  coachId: Scalars['ID'];
  duration: Scalars['Int'];
  eventId?: InputMaybe<Scalars['ID']>;
  eventType?: InputMaybe<Scalars['String']>;
  isTest?: InputMaybe<Scalars['Boolean']>;
  joinURL?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  sessionSubtype?: InputMaybe<CoachSessionSubtype>;
  sessionType?: InputMaybe<CoachSessionType>;
  start: Scalars['ZonedDateTime'];
  useCronofy?: InputMaybe<Scalars['Boolean']>;
};

export type BookSessionPayload = {
  __typename?: 'BookSessionPayload';
  session: CoachSession;
};

export type BulkReassignCoachMembersPayload = {
  __typename?: 'BulkReassignCoachMembersPayload';
  userCountReassigned: Scalars['Int'];
  userIdsNotReassigned: Array<Scalars['String']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  duration: Scalars['Int'];
  end: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  start: Scalars['ZonedDateTime'];
};

export type CalendarBuffer = {
  __typename?: 'CalendarBuffer';
  afterInMinutes: Scalars['Int'];
  beforeInMinutes: Scalars['Int'];
};

export type CalendarProfile = {
  __typename?: 'CalendarProfile';
  platform?: Maybe<ClientCalendarPlatform>;
  profileConnected: Scalars['Boolean'];
  profileId: Scalars['String'];
  profileInitialSyncRequired: Scalars['Boolean'];
  profileName: Scalars['String'];
  providerName: Scalars['String'];
  providerService?: Maybe<Scalars['String']>;
};

export type CancelClassInput = {
  mboClassId: Scalars['Int'];
  mboStudioId: Scalars['String'];
  userId: Scalars['String'];
};

export type CancelClassPayload = {
  __typename?: 'CancelClassPayload';
  mboClass: MboClass;
};

export type Challenge = Node & {
  __typename?: 'Challenge';
  activity: ChallengesActivityConnection;
  auditLogs: AuditLogsConnection;
  background?: Maybe<Photo>;
  badge?: Maybe<Photo>;
  challengeType: ChallengeType;
  clientSpecific: Scalars['Boolean'];
  clients: ChallengeClientsConnection;
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  createdAt: Scalars['ZonedDateTime'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /** @deprecated Use endDateTime */
  endDate: Scalars['LocalDate'];
  endDateTime: Scalars['ZonedDateTime'];
  enrolled: Scalars['Boolean'];
  featured: Scalars['Boolean'];
  goal: Scalars['Int'];
  hasSeenEarnedBadge: Scalars['Boolean'];
  id: Scalars['ID'];
  participants: ChallengeParticipantConnection;
  place: Scalars['String'];
  qualifyingContentGenres: Array<Genre>;
  /** @deprecated Use qualifyingContentGenres */
  qualifyingGenres: Array<Scalars['String']>;
  score: Scalars['Int'];
  share?: Maybe<Share>;
  /** @deprecated Use startDateTime */
  startDate: Scalars['LocalDate'];
  startDateTime: Scalars['ZonedDateTime'];
  subtitle?: Maybe<Scalars['String']>;
  team: Scalars['Boolean'];
  teamScore: Scalars['Int'];
  teams: ChallengeTeamConnection;
  title: Scalars['String'];
  unit: Scalars['String'];
};

export type ChallengeActivityArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamsArgs = {
  filter?: InputMaybe<ChallengeTeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsConnection = Connection & {
  __typename?: 'ChallengeClientsConnection';
  count: Scalars['Int'];
  edges: Array<ChallengeClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type ChallengeClientsEdge = Edge & {
  __typename?: 'ChallengeClientsEdge';
  cursor: Scalars['String'];
  node: Client;
};

export type ChallengeEdge = Edge & {
  __typename?: 'ChallengeEdge';
  cursor: Scalars['String'];
  node: Challenge;
};

export type ChallengeFilter = {
  title?: InputMaybe<Scalars['String']>;
};

export enum ChallengeOrder {
  Enabled = 'enabled',
  EndDate = 'endDate',
  Featured = 'featured',
  StartDate = 'startDate',
  Title = 'title',
}

export type ChallengeParticipantConnection = Connection & {
  __typename?: 'ChallengeParticipantConnection';
  count: Scalars['Int'];
  edges: Array<ChallengeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChallengeParticipantsEdge = Edge & {
  __typename?: 'ChallengeParticipantsEdge';
  challengeTeam?: Maybe<ChallengeTeam>;
  completed: Scalars['Boolean'];
  cursor: Scalars['String'];
  node: User;
  place: Scalars['String'];
  score: Scalars['Int'];
};

export type ChallengeSort = {
  field: ChallengeOrder;
  order: OrderDirection;
};

export type ChallengeTeam = Node & {
  __typename?: 'ChallengeTeam';
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  id: Scalars['ID'];
  joined: Scalars['Boolean'];
  name: Scalars['String'];
  participants: ChallengeParticipantConnection;
};

export type ChallengeTeamParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamConnection = Connection & {
  __typename?: 'ChallengeTeamConnection';
  count: Scalars['Int'];
  edges: Array<ChallengeTeamsEdge>;
  nodes: Array<ChallengeTeam>;
  pageInfo: PageInfo;
};

export type ChallengeTeamsEdge = Edge & {
  __typename?: 'ChallengeTeamsEdge';
  completed: Scalars['Boolean'];
  cursor: Scalars['String'];
  node: ChallengeTeam;
  place: Scalars['String'];
  score: Scalars['Int'];
};

export type ChallengeTeamsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']>;
  excludeTeamsWithoutScore?: InputMaybe<Scalars['Boolean']>;
};

export enum ChallengeType {
  ClassCount = 'ClassCount',
}

export type ChallengesActivityConnection = Connection & {
  __typename?: 'ChallengesActivityConnection';
  count: Scalars['Int'];
  edges: Array<ChallengesActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ChallengesActivityEdge = Edge & {
  __typename?: 'ChallengesActivityEdge';
  cursor: Scalars['String'];
  node: Activity;
};

export type ChallengesFilter = {
  excludeActiveChallenges?: InputMaybe<Scalars['Boolean']>;
  excludeEarnedBadgesSeen?: InputMaybe<Scalars['Boolean']>;
  excludeFutureChallenges?: InputMaybe<Scalars['Boolean']>;
  excludeIncompleteChallenges?: InputMaybe<Scalars['Boolean']>;
  excludePastChallenges?: InputMaybe<Scalars['Boolean']>;
};

export type ChatRoom = Node & {
  __typename?: 'ChatRoom';
  app?: Maybe<App>;
  /** @deprecated No longer supported */
  direct: Scalars['Boolean'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  /** @deprecated No longer supported */
  isStarred: Scalars['Boolean'];
  kind: ChatRoomKind;
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']>;
  lastMessageReceivedAt?: Maybe<Scalars['ZonedDateTime']>;
  /** @deprecated No longer supported */
  latestCoachSessionAt?: Maybe<Scalars['ZonedDateTime']>;
  messages: ChatRoomMessagesConnection;
  name?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  users: ChatRoomUsersConnection;
};

export type ChatRoomMessagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export enum ChatRoomKind {
  Direct = 'direct',
  Generic = 'generic',
  Team = 'team',
}

export type ChatRoomMessagesConnection = Connection & {
  __typename?: 'ChatRoomMessagesConnection';
  count: Scalars['Int'];
  edges: Array<ChatRoomMessagesEdge>;
  nodes: Array<Message>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int'];
};

export type ChatRoomMessagesEdge = Edge & {
  __typename?: 'ChatRoomMessagesEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type ChatRoomUsersConnection = Connection & {
  __typename?: 'ChatRoomUsersConnection';
  count: Scalars['Int'];
  edges: Array<ChatRoomUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChatRoomUsersEdge = Edge & {
  __typename?: 'ChatRoomUsersEdge';
  cursor: Scalars['String'];
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']>;
  node: User;
};

export type ChatRoomsFilter = {
  apps?: InputMaybe<Array<App>>;
  createdAtDateRange?: InputMaybe<DateRange>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
  name?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  userName?: InputMaybe<Scalars['String']>;
};

export enum ChatRoomsOrder {
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LastPerformActivity = 'lastPerformActivity',
  /** @deprecated No longer supported */
  LatestCoachSessionAt = 'latestCoachSessionAt',
  UserJoinedAt = 'userJoinedAt',
  /** @deprecated use lastFitActivity or lastPerformActivity */
  UserLastActivity = 'userLastActivity',
}

export type ChatRoomsOrderBy = {
  direction: OrderDirection;
  order: ChatRoomsOrder;
};

export type ClickThroughMedia = Content &
  Node & {
    __typename?: 'ClickThroughMedia';
    author?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    /** @deprecated use contentGenre */
    formattedGenre?: Maybe<Scalars['String']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    icon?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isPublished: Scalars['Boolean'];
    kind?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    pages: ContentConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    shortDescription?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type ClickThroughMediaPagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClickThroughMediaRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type Client = Node & {
  __typename?: 'Client';
  auditLogs: AuditLogsConnection;
  calendarPlatform?: Maybe<ClientCalendarPlatform>;
  coaches: ClientUsersConnection;
  collectsMemberData: Scalars['Boolean'];
  collectsMemberDataForEEP: Scalars['Boolean'];
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  domains: DomainConnection;
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  logo?: Maybe<Photo>;
  maxFamilyMembers: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  needsUsToFixTheirUserNames: Scalars['Boolean'];
  ssoIdentity?: Maybe<SsoIdentity>;
  studios: Array<Studio>;
  users: ClientUsersConnection;
  usesEligibility: Scalars['Boolean'];
  usesEligibilityServiceType: Scalars['Boolean'];
  usesFitnessCenterForEligibility: Scalars['Boolean'];
  usesGlobalSignOut: Scalars['Boolean'];
  usesHighPerformanceCoaching: Scalars['Boolean'];
  usesPersonalTraining: Scalars['Boolean'];
  usesSSO: Scalars['Boolean'];
  /** validLocations are locations with coach assignments */
  validLocations: CoachLocationsConnection;
  videoPlatform?: Maybe<ClientVideoPlatform>;
};

export type ClientAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientCoachesArgs = {
  coachName?: InputMaybe<Scalars['String']>;
  excludeInvalidCoaches?: InputMaybe<Scalars['Boolean']>;
  input?: InputMaybe<ConnectionInput>;
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  random?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<CoachType>;
};

export type ClientCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String'];
};

export type ClientCronofyExternalCalendarUrlArgs = {
  returnUrl: Scalars['String'];
};

export type ClientDomainsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientStudiosArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  includeCentralStudio?: InputMaybe<Scalars['Boolean']>;
};

export type ClientUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ClientValidLocationsArgs = {
  filter?: InputMaybe<LocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export enum ClientCalendarPlatform {
  Apple = 'apple',
  Exchange = 'exchange',
  ExosOnly = 'exos_only',
  Google = 'google',
  LiveConnect = 'live_connect',
  Office365 = 'office365',
}

export type ClientUsersConnection = Connection & {
  __typename?: 'ClientUsersConnection';
  count: Scalars['Int'];
  edges: Array<ClientUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ClientUsersEdge = Edge & {
  __typename?: 'ClientUsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export enum ClientVideoPlatform {
  EightByEight = 'eight_by_eight',
  GoTo = 'go_to',
  Indeed = 'indeed',
  Integrated = 'integrated',
  MsTeams = 'ms_teams',
  Zoom = 'zoom',
}

export type ClientsFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CoachBasedActivity = {
  coachAvatar?: Maybe<Photo>;
  coachFirstName?: Maybe<Scalars['String']>;
  coachLastName?: Maybe<Scalars['String']>;
};

export type CoachData = {
  __typename?: 'CoachData';
  availability: UserAvailabilityConnection;
  availableForMemberAssignment: Scalars['Boolean'];
  bio: Scalars['String'];
  bodyPhoto?: Maybe<Photo>;
  calendarBuffer: CalendarBuffer;
  calendarConnected: Scalars['Boolean'];
  calendarProfiles: Array<CalendarProfile>;
  calendlyUrl?: Maybe<Scalars['String']>;
  clients: RootClientsConnection;
  coachUsers: CoachUsersConnection;
  coachingStyle?: Maybe<CoachingStyle>;
  conferencingProfiles: Array<ConferencingProfile>;
  cronofyAuthUrl?: Maybe<Scalars['String']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']>;
  cronofyElementToken?: Maybe<Scalars['String']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']>;
  cronofySub?: Maybe<Scalars['String']>;
  cronofyTimezone?: Maybe<Scalars['ZoneId']>;
  dailySessionLimit?: Maybe<Scalars['Int']>;
  degrees: Array<Scalars['String']>;
  externalCalendarConnected?: Maybe<ClientCalendarPlatform>;
  fitCoachId?: Maybe<Scalars['String']>;
  headshot?: Maybe<Photo>;
  id: Scalars['ID'];
  inOfficeDays: Array<Scalars['Int']>;
  /** @deprecated Use calendlyUrl instead */
  inPersonCalendlyUrl?: Maybe<Scalars['String']>;
  locations: CoachLocationsConnection;
  mboStaffId?: Maybe<Scalars['String']>;
  passions: Array<Scalars['String']>;
  sessions: UserCoachSessionsConnection;
  supportedCountries: Array<Country>;
  supportedGoals: Array<Goal>;
  titles: Array<Scalars['String']>;
  totalUnreadMessageCount: Scalars['Int'];
  touchPoints: CoachTouchPointConnection;
  type?: Maybe<CoachType>;
  videoPlatformConnected?: Maybe<ClientVideoPlatform>;
  /** @deprecated We do not use Zoom API directly anymore */
  zoomId?: Maybe<Scalars['String']>;
};

export type CoachDataAvailabilityArgs = {
  bypassOneDayDelay?: InputMaybe<Scalars['Boolean']>;
  duration: Scalars['Int'];
  interval: Scalars['Int'];
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  start: Scalars['ZonedDateTime'];
};

export type CoachDataClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataCoachUsersArgs = {
  filter?: InputMaybe<CoachUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachUsersOrderBy>>;
};

export type CoachDataCronofyAuthUrlArgs = {
  returnUrl: Scalars['String'];
};

export type CoachDataCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String'];
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CoachDataCronofyElementTokenArgs = {
  permissions?: InputMaybe<Array<CronofyElementPermission>>;
};

export type CoachDataCronofyExternalCalendarUrlArgs = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  returnUrl: Scalars['String'];
};

export type CoachDataLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataSessionsArgs = {
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type CoachDataTouchPointsArgs = {
  filter?: InputMaybe<TouchPointFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<TouchPointsOrderBy>>;
};

export type CoachLocation = Node & {
  __typename?: 'CoachLocation';
  coaches: LocationCoachesConnection;
  id: Scalars['ID'];
  isHeadquarters: Scalars['Boolean'];
  mboLocationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  studio?: Maybe<Studio>;
};

export type CoachLocationCoachesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationsConnection = Connection & {
  __typename?: 'CoachLocationsConnection';
  count: Scalars['Int'];
  edges: Array<CoachLocationsEdge>;
  nodes: Array<CoachLocation>;
  pageInfo: PageInfo;
};

export type CoachLocationsEdge = Edge & {
  __typename?: 'CoachLocationsEdge';
  cursor: Scalars['String'];
  node: CoachLocation;
};

export type CoachLocationsFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  studioIsArchived?: InputMaybe<Scalars['Boolean']>;
};

export type CoachSession = Node & {
  __typename?: 'CoachSession';
  additionalInfo?: Maybe<Scalars['String']>;
  attendance?: Maybe<CoachSessionAttendance>;
  attended?: Maybe<Scalars['Boolean']>;
  calendar?: Maybe<Calendar>;
  cancelUrl?: Maybe<Scalars['String']>;
  coach: User;
  id: Scalars['ID'];
  joinableTime: Scalars['ZonedDateTime'];
  location?: Maybe<CoachLocation>;
  meetingUrl?: Maybe<Scalars['String']>;
  member: User;
  reason?: Maybe<Scalars['String']>;
  rescheduleUrl?: Maybe<Scalars['String']>;
  sessionStyle: CoachSessionStyle;
  sessionSubtype?: Maybe<CoachSessionSubtype>;
  sessionType?: Maybe<CoachSessionType>;
  /** @deprecated Use sessionType instead */
  type: Scalars['String'];
};

export enum CoachSessionAttendance {
  Attended = 'Attended',
  Excused = 'Excused',
  NoShow = 'NoShow',
}

export type CoachSessionCredits = {
  __typename?: 'CoachSessionCredits';
  count: Scalars['Int'];
  rowVersion: Scalars['Int'];
};

export type CoachSessionFilter = {
  attendance?: InputMaybe<CoachSessionAttendance>;
  attendances?: InputMaybe<Array<InputMaybe<CoachSessionAttendance>>>;
  coachId?: InputMaybe<Scalars['ID']>;
  coachSessionStyle?: InputMaybe<Array<CoachSessionStyle>>;
  dateRange?: InputMaybe<DateRange>;
  memberName?: InputMaybe<Scalars['String']>;
  sessionType?: InputMaybe<CoachSessionType>;
  sessionTypes?: InputMaybe<Array<CoachSessionType>>;
};

export enum CoachSessionOrder {
  SessionTime = 'sessionTime',
}

export type CoachSessionOrderBy = {
  direction: OrderDirection;
  order: CoachSessionOrder;
};

export enum CoachSessionStyle {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export enum CoachSessionSubtype {
  BodyCompositionAssessment = 'BodyCompositionAssessment',
  ErgonomicEvaluation = 'ErgonomicEvaluation',
  MovementAssessment = 'MovementAssessment',
  NewMemberOrientation = 'NewMemberOrientation',
}

export enum CoachSessionType {
  CoachingSession = 'CoachingSession',
  PersonalTraining = 'PersonalTraining',
  UserCoachingConsult = 'UserCoachingConsult',
  UserNutritionConsult = 'UserNutritionConsult',
}

export type CoachTouchPoint = Node & {
  __typename?: 'CoachTouchPoint';
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  description: Scalars['String'];
  due: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  member: User;
  metadata?: Maybe<CoachTouchPointMetadata>;
  status: CoachTouchPointStatus;
  title: Scalars['String'];
  type: CoachTouchPointType;
};

export type CoachTouchPointConnection = Connection & {
  __typename?: 'CoachTouchPointConnection';
  count: Scalars['Int'];
  edges: Array<CoachTouchPointEdge>;
  nodes: Array<CoachTouchPoint>;
  pageInfo: PageInfo;
};

export type CoachTouchPointEdge = Edge & {
  __typename?: 'CoachTouchPointEdge';
  cursor: Scalars['String'];
  node: CoachTouchPoint;
};

export type CoachTouchPointMetadata = CoachTouchPointSessionMetadata;

export enum CoachTouchPointOrder {
  Due = 'due',
}

export type CoachTouchPointSessionMetadata = {
  __typename?: 'CoachTouchPointSessionMetadata';
  isCancelled: Scalars['Boolean'];
  isRebooked: Scalars['Boolean'];
  session?: Maybe<CoachSession>;
};

export enum CoachTouchPointStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
}

export enum CoachTouchPointType {
  AssessmentNotCompleted = 'AssessmentNotCompleted',
  CompleteFirstDailyCheckIn = 'CompleteFirstDailyCheckIn',
  Custom = 'Custom',
  CustomPlanAdherence = 'CustomPlanAdherence',
  CustomPlanCheckInNote = 'CustomPlanCheckInNote',
  CustomPlanExpired = 'CustomPlanExpired',
  CustomPlanExpiring = 'CustomPlanExpiring',
  DailyCheckInNotCompleted = 'DailyCheckInNotCompleted',
  NewMemberActivationAtRisk = 'NewMemberActivationAtRisk',
  OneWeekPostSessionCheckIn = 'OneWeekPostSessionCheckIn',
  ReassessmentReminder = 'ReassessmentReminder',
  SendMessageReminder = 'SendMessageReminder',
  SessionReminder = 'SessionReminder',
  TrackAttendance = 'TrackAttendance',
}

export enum CoachType {
  Coach = 'Coach',
  Dietitian = 'Dietitian',
  PerformCoach = 'PerformCoach',
}

export enum CoachUserCoachingExperienceTypeFilter {
  HighPerformance = 'highPerformance',
  Wellbeing = 'wellbeing',
}

export enum CoachUserGeneralFilter {
  HasUnreadMessages = 'hasUnreadMessages',
  IsStarred = 'isStarred',
}

export enum CoachUserSessionFilter {
  HasAttendedSession = 'hasAttendedSession',
  HasUpcomingSession = 'hasUpcomingSession',
  NoAttendedSession = 'noAttendedSession',
  NoUpcomingSession = 'noUpcomingSession',
}

export enum CoachUserStatusFilter {
  IsActive = 'isActive',
  IsInactive = 'isInactive',
  IsNew = 'isNew',
}

export type CoachUsersConnection = Connection & {
  __typename?: 'CoachUsersConnection';
  count: Scalars['Int'];
  edges: Array<CoachUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type CoachUsersEdge = Edge & {
  __typename?: 'CoachUsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export type CoachUsersFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  createdAtDateRange?: InputMaybe<DateRange>;
  general?: InputMaybe<Array<CoachUserGeneralFilter>>;
  hasBookedCoachSession?: InputMaybe<Scalars['Boolean']>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  recentActivityDateRange?: InputMaybe<DateRange>;
  session?: InputMaybe<Array<CoachUserSessionFilter>>;
  status?: InputMaybe<Array<CoachUserStatusFilter>>;
  userName?: InputMaybe<Scalars['String']>;
};

export enum CoachUsersOrder {
  /** @deprecated use lastFitActivity or lastPerformActivity */
  LastActivity = 'lastActivity',
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LastPerformActivity = 'lastPerformActivity',
  /** @deprecated No longer supported */
  LatestCoachSessionAt = 'latestCoachSessionAt',
  LatestUserActivityStartTime = 'latestUserActivityStartTime',
}

export type CoachUsersOrderBy = {
  direction: OrderDirection;
  order: CoachUsersOrder;
};

export enum CoachingStyle {
  Hybrid = 'Hybrid',
  Virtual = 'Virtual',
}

export type ConferencingProfile = {
  __typename?: 'ConferencingProfile';
  platform?: Maybe<ClientVideoPlatform>;
  profileConnected: Scalars['Boolean'];
  profileId: Scalars['String'];
  profileName: Scalars['String'];
  providerName: Scalars['String'];
};

export type Connection = {
  count: Scalars['Int'];
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type ConnectionInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type Content = {
  author?: Maybe<Scalars['String']>;
  contentGenre?: Maybe<Genre>;
  /** @deprecated Use subtitle now */
  eyebrow?: Maybe<Scalars['String']>;
  feedback?: Maybe<FeedbackKind>;
  heroPhoto?: Maybe<Photo>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  level?: Maybe<Scalars['String']>;
  recommendations: ContentRecommendationsConnection;
  saved?: Maybe<Scalars['Boolean']>;
  shortDescription?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trainingCommitment?: Maybe<Scalars['String']>;
};

export type ContentRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  id: Scalars['ID'];
  sections: ContentCollectionSectionsConnection;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ContentCollectionSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentCollectionSectionsConnection = Connection & {
  __typename?: 'ContentCollectionSectionsConnection';
  count: Scalars['Int'];
  edges: Array<ContentCollectionSectionsEdge>;
  nodes: Array<ContentSection>;
  pageInfo: PageInfo;
};

export type ContentCollectionSectionsEdge = Edge & {
  __typename?: 'ContentCollectionSectionsEdge';
  cursor: Scalars['String'];
  node: ContentSection;
};

export type ContentConnection = SimpleContentConnection & {
  __typename?: 'ContentConnection';
  completedCount: Scalars['Int'];
  count: Scalars['Int'];
  edges: Array<ContentEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentEdge = SimpleContentEdge & {
  __typename?: 'ContentEdge';
  completed: Scalars['Boolean'];
  cursor: Scalars['String'];
  locked: Scalars['Boolean'];
  node: Content;
  progress?: Maybe<Scalars['Float']>;
};

export type ContentFeedbackPayload = {
  __typename?: 'ContentFeedbackPayload';
  content: Content;
};

export type ContentFilter = {
  brand?: InputMaybe<Array<Scalars['String']>>;
  component?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Array<Scalars['Int']>>;
  genre?: InputMaybe<Array<Scalars['String']>>;
  subGenre?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export enum ContentKind {
  Article = 'article',
  Inappsurvey = 'inappsurvey',
  Link = 'link',
  Video = 'video',
}

export enum ContentOrder {
  PublishedAt = 'published_at',
  Title = 'title',
}

export type ContentOrderBy = {
  direction: OrderDirection;
  order?: InputMaybe<ContentOrder>;
};

export type ContentRecommendationsConnection = SimpleContentConnection & {
  __typename?: 'ContentRecommendationsConnection';
  count: Scalars['Int'];
  edges: Array<ContentRecommendationsEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentRecommendationsEdge = SimpleContentEdge & {
  __typename?: 'ContentRecommendationsEdge';
  cursor: Scalars['String'];
  node: Content;
};

export type ContentSavedConnection = SimpleContentConnection & {
  __typename?: 'ContentSavedConnection';
  count: Scalars['Int'];
  edges: Array<ContentSavedEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentSavedEdge = SimpleContentEdge & {
  __typename?: 'ContentSavedEdge';
  cursor: Scalars['String'];
  node: Content;
};

export type ContentSection = Content &
  Node & {
    __typename?: 'ContentSection';
    author?: Maybe<Scalars['String']>;
    bannerPhoto?: Maybe<Photo>;
    brand?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    contents: ContentConnection;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    isPublished: Scalars['Boolean'];
    level?: Maybe<Scalars['String']>;
    logoPhoto?: Maybe<Photo>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    shortDescription?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type ContentSectionContentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentSectionRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']>;
  badgeId?: InputMaybe<Scalars['String']>;
  challengeType: ChallengeType;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  clientSpecific?: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  endDate: Scalars['LocalDate'];
  featured: Scalars['Boolean'];
  goal: Scalars['Int'];
  qualifyingGenres: Array<Scalars['String']>;
  startDate: Scalars['LocalDate'];
  subtitle?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Scalars['Boolean']>;
  teams?: InputMaybe<Array<CreateChallengeTeamInput>>;
  title: Scalars['String'];
};

export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  challenge: Challenge;
};

export type CreateChallengeTeamInput = {
  name: Scalars['String'];
};

export type CreateChatRoomInput = {
  app?: InputMaybe<App>;
  kind?: InputMaybe<ChatRoomKind>;
  name: Scalars['String'];
  users: Array<Scalars['String']>;
};

export type CreateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  displayName: Scalars['String'];
  domainIds?: InputMaybe<Array<Scalars['String']>>;
  logoId?: InputMaybe<Scalars['String']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']>;
  ssoConnection?: InputMaybe<Scalars['String']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CreateCoachLocationInput = {
  isHeadquarters: Scalars['Boolean'];
  mboLocationId: Scalars['String'];
  name: Scalars['String'];
  stateCode: Scalars['String'];
  studioId: Scalars['String'];
};

export type CreateCoachTouchPointInput = {
  coachId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  due?: InputMaybe<Scalars['ZonedDateTime']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  memberId: Scalars['ID'];
  sessionId?: InputMaybe<Scalars['ID']>;
  sessionTime?: InputMaybe<Scalars['ZonedDateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type: CoachTouchPointType;
};

export type CreateDomainInput = {
  clientId?: InputMaybe<Scalars['String']>;
  domain: Scalars['String'];
};

export type CreateEligibleEmailAddressInput = {
  clientId: Scalars['String'];
  email: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
};

export type CreateNotificationOptionInput = {
  app: App;
  description: Scalars['String'];
  featureFlag?: InputMaybe<Scalars['String']>;
  iterableId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type CreatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  planItems?: InputMaybe<Array<CreatePlanItemInput>>;
  startDate: Scalars['LocalDate'];
  userId: Scalars['ID'];
};

export type CreatePlanItemInput = {
  component?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['ID']>;
  date: Scalars['LocalDate'];
  description?: InputMaybe<Scalars['String']>;
  order: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  type: PlanItemType;
  url?: InputMaybe<Scalars['String']>;
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: Plan;
};

export type CreateStudioInput = {
  clientId: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isCentral?: InputMaybe<Scalars['Boolean']>;
  isVirtual: Scalars['Boolean'];
  mboServiceId: Scalars['String'];
  mboStudioId: Scalars['String'];
  name: Scalars['String'];
  timezone: Scalars['String'];
};

export type CreateTeamInput = {
  chatRoomName?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  contentSlugs?: InputMaybe<Array<Scalars['String']>>;
  createCoachChats?: InputMaybe<Scalars['Boolean']>;
  huddleDayOfWeek?: InputMaybe<Scalars['Int']>;
  huddleTime?: InputMaybe<Scalars['LocalTime']>;
  huddleZoneId?: InputMaybe<Scalars['ZoneId']>;
  name?: InputMaybe<Scalars['String']>;
  programSlug?: InputMaybe<Scalars['String']>;
  programStartDate?: InputMaybe<Scalars['ZonedDateTime']>;
  skipMeetingCreation?: InputMaybe<Scalars['Boolean']>;
  users: Array<Scalars['String']>;
};

export type CreateUserGoalInput = {
  coachId?: InputMaybe<Scalars['ID']>;
  coachNote?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  memberId?: InputMaybe<Scalars['ID']>;
  type: UserGoalType;
};

export type CreateUserGoalPayload = {
  __typename?: 'CreateUserGoalPayload';
  user: User;
};

export type CreateUserInput = {
  adminCreate?: InputMaybe<Scalars['Boolean']>;
  app?: InputMaybe<App>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isTestUser?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user: User;
};

export type CreateUsersInput = {
  users: Array<CreateUserInput>;
};

export type CreateUsersPayload = {
  __typename?: 'CreateUsersPayload';
  users: Array<User>;
};

export enum CronofyElementPermission {
  Agenda = 'agenda',
  Availability = 'availability',
  ManagedAvailability = 'managed_availability',
}

export type CustomPlanActiveDaysFilter = {
  localDateRange: RequiredLocalDateRange;
};

export type CustomPlansFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type CustomTaskPlanItem = Node &
  PlanItem & {
    __typename?: 'CustomTaskPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']>;
    component?: Maybe<Scalars['String']>;
    date: Scalars['LocalDate'];
    description: Scalars['String'];
    id: Scalars['ID'];
    order: Scalars['Int'];
    title: Scalars['String'];
    type: PlanItemType;
    url?: Maybe<Scalars['String']>;
  };

export enum DateFilter {
  Future = 'Future',
  Past = 'Past',
}

export type DateRange = {
  end?: InputMaybe<Scalars['ZonedDateTime']>;
  start?: InputMaybe<Scalars['ZonedDateTime']>;
};

export type DeleteFitCoachPayload = {
  __typename?: 'DeleteFitCoachPayload';
  user: User;
};

export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  plan: Plan;
};

export type DeleteUserGoalPayload = {
  __typename?: 'DeleteUserGoalPayload';
  user: User;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  userId: Scalars['ID'];
};

export type Device = {
  __typename?: 'Device';
  app?: Maybe<App>;
  createdAt: Scalars['ZonedDateTime'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['ZonedDateTime'];
  user: User;
};

export type Distance = {
  __typename?: 'Distance';
  unit?: Maybe<DistanceUnit>;
  value?: Maybe<Scalars['Float']>;
};

export type DistanceInput = {
  unit: DistanceUnit;
  value: Scalars['Float'];
};

export enum DistanceUnit {
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles',
  Yards = 'Yards',
}

export type Domain = Node & {
  __typename?: 'Domain';
  client?: Maybe<Client>;
  createdAt?: Maybe<Scalars['ZonedDateTime']>;
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  count: Scalars['Int'];
  edges: Array<DomainEdge>;
  nodes: Array<Domain>;
  pageInfo: PageInfo;
};

export type DomainEdge = Edge & {
  __typename?: 'DomainEdge';
  cursor: Scalars['String'];
  node: Domain;
};

export type DomainsFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type Edge = {
  cursor: Scalars['String'];
  node: Node;
};

export type EditActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  startTime?: InputMaybe<Scalars['ZonedDateTime']>;
};

export type EligibleEmailAddress = Node & {
  __typename?: 'EligibleEmailAddress';
  archivedAt?: Maybe<Scalars['ZonedDateTime']>;
  auditLogs: AuditLogsConnection;
  client?: Maybe<Client>;
  createdAt: Scalars['ZonedDateTime'];
  email?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  /** @deprecated firstName is no longer required */
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitingRelationship?: Maybe<FamilyRelationship>;
  invitingUser?: Maybe<User>;
  isArchived: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  shareInvitation?: Maybe<Share>;
  updatedAt: Scalars['ZonedDateTime'];
  user?: Maybe<User>;
};

export type EligibleEmailAddressAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type EligibleEmailAddressDetailsInput = {
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type EligibleEmailAddressesFilter = {
  clientId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type EmergencyContactDetails = {
  __typename?: 'EmergencyContactDetails';
  address?: Maybe<Address>;
  emergencyContact?: Maybe<EmergencyContact>;
  updatedAt?: Maybe<Scalars['ZonedDateTime']>;
};

export type EnumAnswer = {
  __typename?: 'EnumAnswer';
  choices: Array<Scalars['String']>;
  enumValue: Array<Scalars['Int']>;
};

export type ExosContentPlanItem = Node &
  PlanItem & {
    __typename?: 'ExosContentPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']>;
    component?: Maybe<Scalars['String']>;
    content: Content;
    date: Scalars['LocalDate'];
    id: Scalars['ID'];
    order: Scalars['Int'];
    type: PlanItemType;
  };

export enum FamilyRelationship {
  Dependent = 'dependent',
  Spouse = 'spouse',
}

export enum FeedbackContextKind {
  Application = 'application',
  Challenge = 'challenge',
  Practice = 'practice',
  PracticeProgram = 'practiceProgram',
  Program = 'program',
}

export enum FeedbackKind {
  Negative = 'negative',
  Positive = 'positive',
  Undecided = 'undecided',
}

export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  challenge?: Maybe<Challenge>;
  content?: Maybe<Content>;
};

export type Genre = {
  __typename?: 'Genre';
  children: Array<Genre>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Genre>;
  value: Scalars['String'];
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String'];
  type: UserGoalType;
};

export type HeartRate = {
  __typename?: 'HeartRate';
  average: Scalars['Int'];
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type Huddle = {
  __typename?: 'Huddle';
  dayOfWeek: Scalars['String'];
  firstHuddle: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  meetingId: Scalars['ID'];
  time: Scalars['LocalTime'];
  url: Scalars['String'];
  zoneId: Scalars['ZoneId'];
};

export type InAppSurvey = Content &
  Node & {
    __typename?: 'InAppSurvey';
    author?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    finishedBody?: Maybe<Scalars['String']>;
    finishedCardSubtitle?: Maybe<Scalars['String']>;
    finishedCardTitle?: Maybe<Scalars['String']>;
    finishedTitle?: Maybe<Scalars['String']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    initialCardSubtitle?: Maybe<Scalars['String']>;
    initialCardTitle?: Maybe<Scalars['String']>;
    introBody?: Maybe<Scalars['String']>;
    introTitle?: Maybe<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    kind?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    link: Scalars['String'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    shortDescription?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    /** @deprecated User Question.savedAnswer now */
    survey?: Maybe<Survey>;
    surveyTemplate?: Maybe<SurveyTemplate>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type InAppSurveyRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type InviteFamilyMemberInput = {
  email: Scalars['String'];
  relationship: FamilyRelationship;
};

export type InviteFamilyMemberPayload = {
  __typename?: 'InviteFamilyMemberPayload';
  familyInvitation: EligibleEmailAddress;
  user: User;
};

export type JoinChallengePayload = {
  __typename?: 'JoinChallengePayload';
  challenge?: Maybe<Challenge>;
};

export type LastActivity = {
  __typename?: 'LastActivity';
  heart?: Maybe<Scalars['ZonedDateTime']>;
  heartWeb?: Maybe<Scalars['ZonedDateTime']>;
  /** @deprecated prince is deprecated */
  prince?: Maybe<Scalars['ZonedDateTime']>;
};

export type Link = Content &
  Node & {
    __typename?: 'Link';
    author?: Maybe<Scalars['String']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    isPublished: Scalars['Boolean'];
    level?: Maybe<Scalars['String']>;
    link: Scalars['String'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    shortDescription?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type LinkRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type LocalDateRange = {
  end?: InputMaybe<Scalars['LocalDate']>;
  start?: InputMaybe<Scalars['LocalDate']>;
};

export type LocationCoachesConnection = Connection & {
  __typename?: 'LocationCoachesConnection';
  count: Scalars['Int'];
  edges: Array<LocationCoachesEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type LocationCoachesEdge = Edge & {
  __typename?: 'LocationCoachesEdge';
  cursor: Scalars['String'];
  node: User;
};

export type LocationsFilter = {
  isVirtual?: InputMaybe<Scalars['Boolean']>;
};

export type MachineClient = {
  __typename?: 'MachineClient';
  id: Scalars['ID'];
};

export type ManualActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']>;
  sourceId: Scalars['String'];
  startTime: Scalars['ZonedDateTime'];
};

export type MboBookingsFilter = {
  dates: DateRange;
};

export type MboClass = Node & {
  __typename?: 'MboClass';
  attendees: MboClassUsersConnection;
  attending: Scalars['Boolean'];
  /** The times at which booking for this class open and close. */
  bookingWindow?: Maybe<ZonedDateTimeRange>;
  cancelled: Scalars['Boolean'];
  category: Scalars['String'];
  client: Client;
  description: Scalars['String'];
  endTime: Scalars['ZonedDateTime'];
  full: Scalars['Boolean'];
  id: Scalars['ID'];
  joinUrl?: Maybe<Scalars['String']>;
  joinableTime: Scalars['ZonedDateTime'];
  location?: Maybe<CoachLocation>;
  mboClassId: Scalars['Int'];
  name: Scalars['String'];
  room?: Maybe<Scalars['String']>;
  /** The service category’s schedule type. */
  scheduleType: Scalars['String'];
  staff?: Maybe<User>;
  staffFirstName?: Maybe<Scalars['String']>;
  staffLastName?: Maybe<Scalars['String']>;
  startTime: Scalars['ZonedDateTime'];
  studio: Studio;
  /** The detailed type of the class: in-person, live, or encore */
  subtype: MboClassSubtype;
  /** @deprecated Prefer subtype */
  type: MboClassType;
};

export type MboClassAttendeesArgs = {
  filter?: InputMaybe<MboClassUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type MboClassActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'MboClassActivity';
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']>;
    coachLastName?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    location?: Maybe<Scalars['String']>;
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    room?: Maybe<Scalars['String']>;
    source?: Maybe<MboClass>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type MboClassConnection = Connection & {
  __typename?: 'MboClassConnection';
  count: Scalars['Int'];
  edges: Array<RootMboClassEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type MboClassDetailsInput = {
  id?: InputMaybe<Scalars['ID']>;
  mboClassId?: InputMaybe<Scalars['Int']>;
  mboStudioId?: InputMaybe<Scalars['String']>;
};

export type MboClassFilter = {
  classType: MboClassType;
  dates: DateRange;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum MboClassSubtype {
  Encore = 'Encore',
  InPerson = 'InPerson',
  LiveVirtual = 'LiveVirtual',
}

export enum MboClassType {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export type MboClassUsersConnection = Connection & {
  __typename?: 'MboClassUsersConnection';
  count: Scalars['Int'];
  edges: Array<MboClassUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type MboClassUsersEdge = Edge & {
  __typename?: 'MboClassUsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export type MboClassUsersFilter = {
  excludeAbsentUsers?: InputMaybe<Scalars['Boolean']>;
  includeCancelledUsers?: InputMaybe<Scalars['Boolean']>;
};

export type Message = Node & {
  __typename?: 'Message';
  automated: Scalars['Boolean'];
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  photos: Array<Photo>;
  previews: Array<MessagePreview>;
  reactions: Array<Reaction>;
  text: Scalars['String'];
  user: User;
};

export type MessagePreview = {
  __typename?: 'MessagePreview';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Photo>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ModalStatus = {
  __typename?: 'ModalStatus';
  id: Scalars['ID'];
  modalSeen: Scalars['Boolean'];
};

export type MultipleChoiceOption = {
  __typename?: 'MultipleChoiceOption';
  description: Scalars['String'];
  optionIdentifier: Scalars['String'];
  score: Scalars['Int'];
  text: Scalars['String'];
  textEntry: Scalars['Boolean'];
};

export type MutateChatRoomPayload = {
  __typename?: 'MutateChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
};

export type MutateClientPayload = {
  __typename?: 'MutateClientPayload';
  client: Client;
};

export type MutateCoachLocationPayload = {
  __typename?: 'MutateCoachLocationPayload';
  coachLocation: CoachLocation;
};

export type MutateCoachPayload = {
  __typename?: 'MutateCoachPayload';
  coachData: CoachData;
};

export type MutateDomainPayload = {
  __typename?: 'MutateDomainPayload';
  domain: Domain;
};

export type MutateEligibleEmailAddressInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
};

export type MutateEligibleEmailAddressPayload = {
  __typename?: 'MutateEligibleEmailAddressPayload';
  eligibleEmailAddress: EligibleEmailAddress;
};

export type MutateNotePayload = {
  __typename?: 'MutateNotePayload';
  note?: Maybe<Note>;
};

export type MutateNotificationOptionPayload = {
  __typename?: 'MutateNotificationOptionPayload';
  notificationOption: NotificationOption;
};

export type MutateStudioPayload = {
  __typename?: 'MutateStudioPayload';
  studio: Studio;
};

export type MutateTeamPayload = {
  __typename?: 'MutateTeamPayload';
  team: Team;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManualActivity: ActivityPayload;
  addReaction: Message;
  addSaved: SavedPayload;
  addToChatRoom: MutateChatRoomPayload;
  addToClient: MutateClientPayload;
  addToTeam: MutateTeamPayload;
  addUserToStudio: AddUserToStudioPayload;
  adminBackfillAssessmentAttributionType: Scalars['Int'];
  adminCancelClass: Scalars['Boolean'];
  adminChangeUserAuthProvider: UpdateUserPayload;
  adminConfirmUser: UpdateUserPayload;
  adminCreateClass: Scalars['Boolean'];
  adminUpdateLastActivity?: Maybe<UpdateUserPayload>;
  adminUpdateUser: UpdateUserPayload;
  answerSurvey: Survey;
  /** @deprecated use `updateCoachTouchPoint` instead */
  archiveCoachTouchPoint: ArchiveCoachTouchPointPayload;
  archiveTeam: Scalars['Boolean'];
  assignAssessments: AssignAssessmentsPayload;
  assignFitCoach: AssignFitCoachPayload;
  assignHistoricalFitCoach: AssignFitCoachPayload;
  assignPractice: AssignPracticePayload;
  assignProgram: AssignProgramPayload;
  backFillIterableMarketing: RootUsersConnection;
  backfillEligibleEmailAddressArchivedAt: Scalars['Int'];
  blockUser: BlockUserPayload;
  bookClass: BookClassPayload;
  bookSession: BookSessionPayload;
  bulkReassignCoachMembers: BulkReassignCoachMembersPayload;
  cancelClass: CancelClassPayload;
  cancelSession: Scalars['Boolean'];
  changeEmail: UpdateUserPayload;
  clearGenresCache: Scalars['Boolean'];
  completePractice: UpdatePracticePayload;
  completeProgram: UpdateProgramPayload;
  createChallenge: CreateChallengePayload;
  createChatRoom: MutateChatRoomPayload;
  createClient: MutateClientPayload;
  createCoachLocation: MutateCoachLocationPayload;
  createCoachTouchPoint: CoachTouchPoint;
  /** @deprecated Use createFeedback */
  createContentFeedback: ContentFeedbackPayload;
  createDomain: MutateDomainPayload;
  createEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  createFeedback: FeedbackPayload;
  createNotificationOption: MutateNotificationOptionPayload;
  createPlan: CreatePlanPayload;
  createStudio: MutateStudioPayload;
  createTeam: MutateTeamPayload;
  createUser: CreateUserPayload;
  createUserGoal: CreateUserGoalPayload;
  createUsers: CreateUsersPayload;
  deleteActivity: Scalars['Boolean'];
  deleteAssignedAssessment?: Maybe<AssessmentAssignment>;
  deleteClientEligibleEmails: Scalars['Int'];
  deleteCoachTouchPoint: Scalars['Boolean'];
  deleteFitCoach: DeleteFitCoachPayload;
  deletePlan: DeletePlanPayload;
  deleteUser: DeleteUserPayload;
  deleteUserGoal: DeleteUserGoalPayload;
  editActivity: ActivityPayload;
  generateSignedUrl: SignedUrlPayload;
  inviteFamilyMember: InviteFamilyMemberPayload;
  joinChallenge: JoinChallengePayload;
  joinLiveClass: ActivityPayload;
  leavePractice: UpdatePracticePayload;
  leaveProgram: UpdateProgramPayload;
  markBadgesSeenForChallenge: Challenge;
  markChatRead: ChatRoom;
  /** @deprecated No longer supported */
  markChatStarred: ChatRoom;
  markPlanItemComplete: PlanItem;
  markSurveyResponseViewed: SurveyResponsePayload;
  markUserStarred: User;
  purgeContents: Scalars['Boolean'];
  purgeWearableData: PurgeWearableDataPayload;
  rateApp: RateAppPayload;
  rebookSession: BookSessionPayload;
  refreshContent: RefreshContentPayload;
  register: RegisterUserPayload;
  registerDevice: Scalars['Boolean'];
  remindFamilyMember: InviteFamilyMemberPayload;
  removeAllUserSurveys: Scalars['Int'];
  removeClient: Scalars['Boolean'];
  removeCoachMembers: Scalars['Boolean'];
  removeDomain: Scalars['Boolean'];
  removeFamilyMember: InviteFamilyMemberPayload;
  removeFromChallenge: JoinChallengePayload;
  removeFromChatRoom: MutateChatRoomPayload;
  removeFromClient: MutateClientPayload;
  removeFromTeam: MutateTeamPayload;
  removeLocation: Scalars['Boolean'];
  removeReaction: Message;
  removeSaved: SavedPayload;
  removeStudio: Scalars['Boolean'];
  removeTeam: Scalars['Boolean'];
  removeUserSurveys: Scalars['Int'];
  repairClientUsers: RepairClientUsersPayload;
  reportUser: Scalars['Boolean'];
  resetPreview?: Maybe<Photo>;
  resetProgress: ResetProgressPayload;
  resyncIterableUser?: Maybe<User>;
  /** @deprecated Use syncWearableSamples */
  saveWearableSamples: Scalars['Boolean'];
  sendHistoricalMessage: Message;
  sendMessage: Message;
  sendSupportRequest: Scalars['Boolean'];
  setCurrentTeam: SetCurrentTeamPayload;
  setMinBuildNumber: Scalars['Int'];
  setModalStatus: ModalStatus;
  setNotificationOptions: SetNotificationOptionsPayload;
  setSessionCredits: SessionCreditsPayload;
  setUnitsPreference: UnitsPayload;
  startPractice: StartPracticePayload;
  startProgram: StartProgramPayload;
  /** @deprecated No longer supported */
  syncChatUsers: Scalars['Boolean'];
  syncEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  syncMboMemberships: SyncMboMembershipsPayload;
  syncWearableSamples: Scalars['Boolean'];
  triggerIterablePush?: Maybe<TriggerIterablePushPayload>;
  unforgetIterableUser: Scalars['Boolean'];
  unforgetUser: UnforgetUserPayload;
  updateAttendance: CoachSession;
  /** @deprecated Moved to enum column "updateAttendance" */
  updateAttended: CoachSession;
  updateAvatar: UpdateAvatarPayload;
  updateChallenge: CreateChallengePayload;
  updateChatRoom: MutateChatRoomPayload;
  updateClient: MutateClientPayload;
  updateCoachCronofyTimezone: UpdateCoachCronofyTimezoneResponse;
  updateCoachData: UpdateCoachDataPayload;
  updateCoachLocation: MutateCoachLocationPayload;
  updateCoachTouchPoint: CoachTouchPoint;
  updateDomain: MutateDomainPayload;
  updateEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  updateLastActivity?: Maybe<UpdateUserPayload>;
  updateNote: MutateNotePayload;
  updateNotificationOption: MutateNotificationOptionPayload;
  updatePlan: UpdatePlanPayload;
  updatePractice: UpdatePracticePayload;
  updateProfileJson: UpdateProfileJsonPayload;
  updateProgram: UpdateProgramPayload;
  updateProgress: ContentEdge;
  updateStudio: MutateStudioPayload;
  updateTeam: MutateTeamPayload;
  updateUnreadCount: Scalars['Int'];
  updateUser: UpdateUserPayload;
  updateUserGoal: UpdateUserGoalPayload;
  /** @deprecated Removed welcome section */
  updateWelcomeSectionDisplay: UpdateUserPayload;
};

export type MutationAddManualActivityArgs = {
  activity: ManualActivityInput;
};

export type MutationAddReactionArgs = {
  input: AddReactionInput;
};

export type MutationAddSavedArgs = {
  contentId: Scalars['ID'];
};

export type MutationAddToChatRoomArgs = {
  chatRoomId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationAddToClientArgs = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAddToTeamArgs = {
  createCoachChat?: InputMaybe<Scalars['Boolean']>;
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAddUserToStudioArgs = {
  studioId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAdminBackfillAssessmentAttributionTypeArgs = {
  input: AdminBackfillAssessmentAttributionTypeInput;
};

export type MutationAdminCancelClassArgs = {
  input: CancelClassInput;
};

export type MutationAdminChangeUserAuthProviderArgs = {
  input: AdminChangeAuthProviderInput;
};

export type MutationAdminConfirmUserArgs = {
  userId: Scalars['ID'];
};

export type MutationAdminCreateClassArgs = {
  input: BookClassInput;
};

export type MutationAdminUpdateLastActivityArgs = {
  app: App;
  date?: InputMaybe<Scalars['ZonedDateTime']>;
  platform?: TokenPlatform;
  userId: Scalars['ID'];
};

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};

export type MutationAnswerSurveyArgs = {
  input: AnswerSurveyInput;
};

export type MutationArchiveCoachTouchPointArgs = {
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
};

export type MutationArchiveTeamArgs = {
  teamId: Scalars['ID'];
};

export type MutationAssignAssessmentsArgs = {
  input: AssignAssessmentsInput;
};

export type MutationAssignFitCoachArgs = {
  coachId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationAssignHistoricalFitCoachArgs = {
  coachId: Scalars['ID'];
  createdAt: Scalars['ZonedDateTime'];
  userId: Scalars['ID'];
};

export type MutationAssignPracticeArgs = {
  practiceId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAssignProgramArgs = {
  programId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationBackFillIterableMarketingArgs = {
  input?: InputMaybe<ConnectionInput>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationBookClassArgs = {
  classId: Scalars['ID'];
};

export type MutationBookSessionArgs = {
  input: BookSessionInput;
};

export type MutationBulkReassignCoachMembersArgs = {
  newCoachId: Scalars['ID'];
  oldCoachId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type MutationCancelClassArgs = {
  classId: Scalars['ID'];
};

export type MutationCancelSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']>;
  sessionId: Scalars['ID'];
  useCronofy?: InputMaybe<Scalars['Boolean']>;
};

export type MutationChangeEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationCompletePracticeArgs = {
  practiceId: Scalars['ID'];
};

export type MutationCompleteProgramArgs = {
  programId: Scalars['ID'];
};

export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};

export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateCoachLocationArgs = {
  input: CreateCoachLocationInput;
};

export type MutationCreateCoachTouchPointArgs = {
  input: CreateCoachTouchPointInput;
};

export type MutationCreateContentFeedbackArgs = {
  contentId: Scalars['ID'];
  context: FeedbackContextKind;
  feedback: FeedbackKind;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateEligibleEmailAddressArgs = {
  input: CreateEligibleEmailAddressInput;
};

export type MutationCreateFeedbackArgs = {
  context: FeedbackContextKind;
  feedback: FeedbackKind;
  id: Scalars['ID'];
};

export type MutationCreateNotificationOptionArgs = {
  input: CreateNotificationOptionInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreateStudioArgs = {
  input: CreateStudioInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserGoalArgs = {
  input: CreateUserGoalInput;
};

export type MutationCreateUsersArgs = {
  input: CreateUsersInput;
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAssignedAssessmentArgs = {
  assignmentId: Scalars['ID'];
};

export type MutationDeleteClientEligibleEmailsArgs = {
  clientId: Scalars['String'];
};

export type MutationDeleteCoachTouchPointArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFitCoachArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationDeletePlanArgs = {
  planId: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  forgetIterable?: Scalars['Boolean'];
  jira?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type MutationDeleteUserGoalArgs = {
  userId: Scalars['ID'];
};

export type MutationEditActivityArgs = {
  activity: EditActivityInput;
};

export type MutationGenerateSignedUrlArgs = {
  input: SignedUrlInput;
};

export type MutationInviteFamilyMemberArgs = {
  input: InviteFamilyMemberInput;
};

export type MutationJoinChallengeArgs = {
  challengeTeamId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationJoinLiveClassArgs = {
  classId: Scalars['String'];
};

export type MutationLeavePracticeArgs = {
  practiceId: Scalars['ID'];
};

export type MutationLeaveProgramArgs = {
  programId: Scalars['ID'];
};

export type MutationMarkBadgesSeenForChallengeArgs = {
  id: Scalars['ID'];
};

export type MutationMarkChatReadArgs = {
  chatId: Scalars['ID'];
  lastMessageRead: Scalars['ZonedDateTime'];
};

export type MutationMarkChatStarredArgs = {
  chatId: Scalars['ID'];
  isStarred: Scalars['Boolean'];
};

export type MutationMarkPlanItemCompleteArgs = {
  id: Scalars['ID'];
};

export type MutationMarkSurveyResponseViewedArgs = {
  surveyResponseId: Scalars['ID'];
};

export type MutationMarkUserStarredArgs = {
  isStarred: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type MutationPurgeWearableDataArgs = {
  userId: Scalars['ID'];
};

export type MutationRateAppArgs = {
  feedback: FeedbackKind;
};

export type MutationRebookSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']>;
  sessionId: Scalars['ID'];
  start: Scalars['ZonedDateTime'];
  update?: InputMaybe<RebookSessionUpdateInput>;
  useCronofy?: InputMaybe<Scalars['Boolean']>;
};

export type MutationRefreshContentArgs = {
  id: Scalars['ID'];
};

export type MutationRegisterArgs = {
  input: RegisterUserInput;
};

export type MutationRegisterDeviceArgs = {
  app?: InputMaybe<App>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  token: Scalars['String'];
};

export type MutationRemindFamilyMemberArgs = {
  input: RemindFamilyMemberInput;
};

export type MutationRemoveClientArgs = {
  clientId: Scalars['ID'];
};

export type MutationRemoveCoachMembersArgs = {
  clientId: Scalars['ID'];
  coachId: Scalars['ID'];
};

export type MutationRemoveDomainArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveFamilyMemberArgs = {
  input: RemoveFamilyMemberInput;
};

export type MutationRemoveFromChallengeArgs = {
  challengeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveFromChatRoomArgs = {
  chatRoomId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationRemoveFromClientArgs = {
  clientId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveFromTeamArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveLocationArgs = {
  locationId: Scalars['ID'];
};

export type MutationRemoveReactionArgs = {
  reactionId: Scalars['ID'];
};

export type MutationRemoveSavedArgs = {
  contentId: Scalars['ID'];
};

export type MutationRemoveStudioArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTeamArgs = {
  teamId: Scalars['ID'];
};

export type MutationRemoveUserSurveysArgs = {
  surveyIdentifier: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationRepairClientUsersArgs = {
  clientId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type MutationReportUserArgs = {
  input: ReportUserInput;
};

export type MutationResetPreviewArgs = {
  extension?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationResetProgressArgs = {
  contentId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type MutationResyncIterableUserArgs = {
  userId: Scalars['ID'];
};

export type MutationSaveWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationSendHistoricalMessageArgs = {
  chatId: Scalars['ID'];
  createdAt: Scalars['ZonedDateTime'];
  text: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationSendMessageArgs = {
  chatId: Scalars['ID'];
  messageId?: InputMaybe<Scalars['ID']>;
  optimisticId?: InputMaybe<Scalars['ID']>;
  text: Scalars['String'];
};

export type MutationSendSupportRequestArgs = {
  app: App;
  type: SupportRequestType;
};

export type MutationSetCurrentTeamArgs = {
  teamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationSetMinBuildNumberArgs = {
  input: SetMinBuildNumberInput;
};

export type MutationSetModalStatusArgs = {
  status: SetModalStatus;
};

export type MutationSetNotificationOptionsArgs = {
  input: Array<NotificationOptionInput>;
};

export type MutationSetSessionCreditsArgs = {
  input: SessionCreditsInput;
};

export type MutationSetUnitsPreferenceArgs = {
  input: UnitsInput;
};

export type MutationStartPracticeArgs = {
  practiceId: Scalars['ID'];
};

export type MutationStartProgramArgs = {
  programId: Scalars['ID'];
};

export type MutationSyncEligibleEmailAddressArgs = {
  input: SyncEligibleEmailAddressInput;
};

export type MutationSyncMboMembershipsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationSyncWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationTriggerIterablePushArgs = {
  campaignId: Scalars['Int'];
  dataFields?: InputMaybe<Scalars['JSONObject']>;
  userId: Scalars['ID'];
};

export type MutationUnforgetIterableUserArgs = {
  userId: Scalars['ID'];
};

export type MutationUnforgetUserArgs = {
  userId: Scalars['ID'];
};

export type MutationUpdateAttendanceArgs = {
  attendance: CoachSessionAttendance;
  sessionId: Scalars['ID'];
};

export type MutationUpdateAttendedArgs = {
  attended: Scalars['Boolean'];
  sessionId: Scalars['ID'];
};

export type MutationUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};

export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoomInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateCoachCronofyTimezoneArgs = {
  input: UpdateCoachCronofyTimezoneInput;
};

export type MutationUpdateCoachDataArgs = {
  input: UpdateCoachDataInput;
};

export type MutationUpdateCoachLocationArgs = {
  input: UpdateCoachLocationInput;
};

export type MutationUpdateCoachTouchPointArgs = {
  input: UpdateCoachTouchPointInput;
};

export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};

export type MutationUpdateEligibleEmailAddressArgs = {
  input: MutateEligibleEmailAddressInput;
};

export type MutationUpdateLastActivityArgs = {
  app: App;
  platform?: TokenPlatform;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateNotificationOptionArgs = {
  input: UpdateNotificationOptionInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePracticeArgs = {
  practiceId: Scalars['ID'];
};

export type MutationUpdateProfileJsonArgs = {
  input: UpdateProfileJsonInput;
};

export type MutationUpdateProgramArgs = {
  programId: Scalars['ID'];
};

export type MutationUpdateProgressArgs = {
  contentId: Scalars['ID'];
  progress?: InputMaybe<Scalars['Float']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateStudioArgs = {
  input: UpdateStudioInput;
};

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};

export type MutationUpdateUnreadCountArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserGoalArgs = {
  input: UpdateUserGoalInput;
};

export type MutationUpdateWelcomeSectionDisplayArgs = {
  display: WelcomeSectionDisplay;
};

export type NewMessagePayload = {
  __typename?: 'NewMessagePayload';
  chatRoom: ChatRoom;
  message: Message;
  optimisticId?: Maybe<Scalars['ID']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NotHello = {
  __typename?: 'NotHello';
  not?: Maybe<Scalars['String']>;
};

export type Note = Node & {
  __typename?: 'Note';
  /** @deprecated No longer supported */
  coach?: Maybe<User>;
  content: Scalars['String'];
  createdAt: Scalars['ZonedDateTime'];
  /** @deprecated No longer supported */
  id: Scalars['ID'];
  lastUpdatedBy?: Maybe<User>;
  /** @deprecated No longer supported */
  member: User;
  updatedAt: Scalars['ZonedDateTime'];
};

export enum NotificationKind {
  Push = 'push',
}

export type NotificationOption = Node & {
  __typename?: 'NotificationOption';
  app: App;
  description: Scalars['String'];
  featureFlag?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iterableId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  push: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type NotificationOptionConnection = Connection & {
  __typename?: 'NotificationOptionConnection';
  count: Scalars['Int'];
  edges: Array<NotificationOptionEdge>;
  nodes: Array<NotificationOption>;
  pageInfo: PageInfo;
};

export type NotificationOptionEdge = Edge & {
  __typename?: 'NotificationOptionEdge';
  cursor: Scalars['String'];
  node: NotificationOption;
};

export type NotificationOptionInput = {
  optionId: Scalars['String'];
  push?: InputMaybe<Scalars['Boolean']>;
};

export type NumberAnswer = {
  __typename?: 'NumberAnswer';
  numberValue: Scalars['Float'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Os {
  Android = 'android',
  Ios = 'ios',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type ParticipantsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']>;
  challengeTeamId?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']>;
  excludeUsersWithoutScore?: InputMaybe<Scalars['Boolean']>;
};

export enum ParticipantsOrder {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  Score = 'score',
}

export type ParticipantsOrderBy = {
  direction: OrderDirection;
  order: ParticipantsOrder;
};

export type Photo = Node & {
  __typename?: 'Photo';
  basePath: Scalars['String'];
  id: Scalars['ID'];
  preview?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type PhotoUrlArgs = {
  circle?: InputMaybe<Scalars['Boolean']>;
  extension?: InputMaybe<Scalars['String']>;
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type PhotoAnswer = {
  __typename?: 'PhotoAnswer';
  caption?: Maybe<Scalars['String']>;
  photo: Photo;
};

export type PhysicalActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'PhysicalActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    source?: Maybe<ActivityGenre>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type Plan = Node & {
  __typename?: 'Plan';
  endDate: Scalars['LocalDate'];
  id: Scalars['ID'];
  name: Scalars['String'];
  planItems: PlanItemConnection;
  startDate: Scalars['LocalDate'];
};

export type PlanPlanItemsArgs = {
  filter?: InputMaybe<PlanItemsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PlanItem = {
  completedAt?: Maybe<Scalars['ZonedDateTime']>;
  date: Scalars['LocalDate'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  type: PlanItemType;
};

export type PlanItemConnection = Connection & {
  __typename?: 'PlanItemConnection';
  count: Scalars['Int'];
  edges: Array<PlanItemEdge>;
  nodes: Array<PlanItem>;
  pageInfo: PageInfo;
};

export type PlanItemEdge = Edge & {
  __typename?: 'PlanItemEdge';
  cursor: Scalars['String'];
  node: PlanItem;
};

export enum PlanItemType {
  CustomTask = 'CustomTask',
  ExosContent = 'ExosContent',
}

export type PlanItemsFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type PracticeParticipantConnection = Connection & {
  __typename?: 'PracticeParticipantConnection';
  count: Scalars['Int'];
  edges: Array<PracticeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type PracticeParticipantsEdge = Edge & {
  __typename?: 'PracticeParticipantsEdge';
  cursor: Scalars['String'];
  node: User;
};

export type PracticeParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']>;
};

export type PracticeProgram = Content &
  Node & {
    __typename?: 'PracticeProgram';
    author?: Maybe<Scalars['String']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']>;
    congratulations?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    createdAt?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    isPublished: Scalars['Boolean'];
    level?: Maybe<Scalars['String']>;
    participants: PracticeParticipantConnection;
    progress: Scalars['Float'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    sections: ContentCollectionSectionsConnection;
    shortDescription?: Maybe<Scalars['String']>;
    skills: Array<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type PracticeProgramParticipantsArgs = {
  filter?: InputMaybe<PracticeParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramFilter = {
  status?: InputMaybe<PracticeProgramStatus>;
};

export enum PracticeProgramStatus {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum Prefix {
  Avatar = 'avatar',
  Challenge = 'challenge',
  Client = 'client',
  Coach = 'coach',
  Uploads = 'uploads',
}

export type Principal = MachineClient | User;

export type Program = Content &
  Node & {
    __typename?: 'Program';
    author?: Maybe<Scalars['String']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']>;
    congratulations?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    createdAt?: Maybe<Scalars['ZonedDateTime']>;
    currentSection?: Maybe<ContentSection>;
    equipment: Array<Scalars['String']>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    isPublished: Scalars['Boolean'];
    level?: Maybe<Scalars['String']>;
    participants: ProgramParticipantConnection;
    progress: Scalars['Float'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    sections: ContentCollectionSectionsConnection;
    shortDescription?: Maybe<Scalars['String']>;
    skills: Array<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
  };

export type ProgramParticipantsArgs = {
  filter?: InputMaybe<ProgramParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramParticipantConnection = Connection & {
  __typename?: 'ProgramParticipantConnection';
  count: Scalars['Int'];
  edges: Array<ProgramParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ProgramParticipantsEdge = Edge & {
  __typename?: 'ProgramParticipantsEdge';
  cursor: Scalars['String'];
  node: User;
};

export type ProgramParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']>;
};

export type ProgramsConnection = Connection & {
  __typename?: 'ProgramsConnection';
  count: Scalars['Int'];
  edges: Array<ProgramsEdge>;
  nodes: Array<Program>;
  pageInfo: PageInfo;
};

export type ProgramsEdge = Edge & {
  __typename?: 'ProgramsEdge';
  cursor: Scalars['String'];
  node: Program;
};

export type PublicSurveyResponseFilter = {
  week?: InputMaybe<Scalars['Int']>;
};

export type PurgeWearableDataPayload = {
  __typename?: 'PurgeWearableDataPayload';
  user: User;
};

export type Query = {
  __typename?: 'Query';
  activity?: Maybe<Activity>;
  activityGenre?: Maybe<ActivityGenre>;
  activityGenres: Array<ActivityGenre>;
  /** @deprecated No longer supported */
  article?: Maybe<Article>;
  /** @deprecated No longer supported */
  articles: ArticlesConnection;
  assessment?: Maybe<AssessmentAssignment>;
  auditLogs: AuditLogsConnection;
  availableAssessments: AssessmentSurveysConnection;
  challenge?: Maybe<Challenge>;
  challengeTeam?: Maybe<ChallengeTeam>;
  challenges: RootChallengesConnection;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms: RootChatRoomsConnection;
  client?: Maybe<Client>;
  clients: RootClientsConnection;
  coachLocation?: Maybe<CoachLocation>;
  coachLocations: CoachLocationsConnection;
  coachSession?: Maybe<CoachSession>;
  content?: Maybe<Content>;
  contentCollection?: Maybe<ContentCollection>;
  contentGenres: Array<Genre>;
  contents: ContentConnection;
  countries: Array<Country>;
  customPlan: Plan;
  domain?: Maybe<Domain>;
  domains: DomainConnection;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  eligibleEmailAddresses: RootEligibleEmailAddressesConnection;
  getModalStatus: ModalStatus;
  hello: Scalars['String'];
  /** @deprecated No longer supported */
  home: ContentConnection;
  manyChallenges: Array<Challenge>;
  manyChatRooms: Array<ChatRoom>;
  manyClients: Array<Client>;
  manyCoachLocations: Array<CoachLocation>;
  manyDomains: Array<Domain>;
  manyEligibleEmailAddresses: Array<EligibleEmailAddress>;
  manyStudios: Array<Studio>;
  manyTeams: Array<Team>;
  manyUsers: Array<User>;
  mboClass?: Maybe<MboClass>;
  /** @deprecated No longer supported */
  notHello?: Maybe<NotHello>;
  notificationOption: NotificationOption;
  notificationOptions: NotificationOptionConnection;
  planItem: PlanItem;
  programs: ProgramsConnection;
  self?: Maybe<User>;
  studio?: Maybe<Studio>;
  studios: StudiosConnection;
  survey?: Maybe<Survey>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  systemPreferences: SystemPreferences;
  team?: Maybe<Team>;
  teams: RootTeamsConnection;
  /** @deprecated use user.coachData.totalUnreadMessageCount */
  totalUnreadMessageCount: Scalars['Int'];
  user?: Maybe<User>;
  userActivity?: Maybe<UserActivity>;
  userAssessmentSurvey: UserAssessmentSurvey;
  users: RootUsersConnection;
};

export type QueryActivityArgs = {
  id: Scalars['ID'];
};

export type QueryActivityGenreArgs = {
  id: Scalars['ID'];
};

export type QueryActivityGenresArgs = {
  filter?: InputMaybe<ActivityGenresFilter>;
};

export type QueryArticleArgs = {
  id: Scalars['ID'];
};

export type QueryAssessmentArgs = {
  id: Scalars['ID'];
};

export type QueryAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryAvailableAssessmentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryChallengeArgs = {
  id: Scalars['ID'];
};

export type QueryChallengeTeamArgs = {
  id: Scalars['ID'];
};

export type QueryChallengesArgs = {
  filter?: InputMaybe<ChallengeFilter>;
  input?: InputMaybe<ConnectionInput>;
  sort?: InputMaybe<ChallengeSort>;
};

export type QueryChatRoomArgs = {
  id?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryChatRoomsArgs = {
  filter?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type QueryClientArgs = {
  domain?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachLocationArgs = {
  id: Scalars['ID'];
};

export type QueryCoachLocationsArgs = {
  filter?: InputMaybe<CoachLocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachSessionArgs = {
  id: Scalars['ID'];
};

export type QueryContentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryContentCollectionArgs = {
  slug: Scalars['String'];
};

export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<ContentOrderBy>;
};

export type QueryCustomPlanArgs = {
  id: Scalars['ID'];
};

export type QueryDomainArgs = {
  id: Scalars['ID'];
};

export type QueryDomainsArgs = {
  filter?: InputMaybe<DomainsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryEligibleEmailAddressArgs = {
  details?: InputMaybe<EligibleEmailAddressDetailsInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryEligibleEmailAddressesArgs = {
  filter?: InputMaybe<EligibleEmailAddressesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryGetModalStatusArgs = {
  id: Scalars['ID'];
};

export type QueryManyChallengesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyChatRoomsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyClientsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyCoachLocationsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyDomainsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyEligibleEmailAddressesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyStudiosArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyTeamsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryManyUsersArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryMboClassArgs = {
  input: MboClassDetailsInput;
};

export type QueryNotificationOptionArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationOptionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPlanItemArgs = {
  id: Scalars['ID'];
};

export type QueryProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryStudioArgs = {
  id: Scalars['ID'];
};

export type QueryStudiosArgs = {
  filter?: InputMaybe<StudiosFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QuerySurveyArgs = {
  id: Scalars['ID'];
};

export type QuerySurveyTemplateArgs = {
  id: Scalars['ID'];
  scope?: InputMaybe<Scalars['String']>;
};

export type QueryTeamArgs = {
  id: Scalars['ID'];
};

export type QueryTeamsArgs = {
  filter?: InputMaybe<TeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryTotalUnreadMessageCountArgs = {
  apps?: InputMaybe<Array<App>>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mboId?: InputMaybe<Scalars['String']>;
};

export type QueryUserActivityArgs = {
  id: Scalars['ID'];
};

export type QueryUserAssessmentSurveyArgs = {
  surveyContentId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type Question = {
  __typename?: 'Question';
  answer?: Maybe<Answer>;
  choices: Array<MultipleChoiceOption>;
  id: Scalars['ID'];
  isResponseVisibleToTeam: Scalars['Boolean'];
  questionSubtype?: Maybe<QuestionSubtype>;
  questionType: QuestionType;
  required: Scalars['Boolean'];
  text: Scalars['String'];
};

export enum QuestionSubtype {
  Multiselect = 'Multiselect',
  OpenTextBox = 'OpenTextBox',
  PhotoUpload = 'PhotoUpload',
  Radio = 'Radio',
  SingleLine = 'SingleLine',
}

export enum QuestionType {
  MultipleChoice = 'MultipleChoice',
  PhotoUpload = 'PhotoUpload',
  TextEntry = 'TextEntry',
}

export type RateAppPayload = {
  __typename?: 'RateAppPayload';
  user?: Maybe<User>;
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID'];
  kind: ReactionKind;
  user: User;
};

export enum ReactionKind {
  Clap = 'clap',
  Fire = 'fire',
  Grimacing = 'grimacing',
  Laugh = 'laugh',
  Smile = 'smile',
  Thumbsup = 'thumbsup',
}

export type RebookSessionUpdateInput = {
  duration: Scalars['Int'];
  locationId?: InputMaybe<Scalars['ID']>;
  sessionStyle: CoachSessionStyle;
  sessionType: CoachSessionType;
};

export type RefreshContentPayload = {
  __typename?: 'RefreshContentPayload';
  content?: Maybe<Content>;
};

export type RegisterUserInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  consentDate?: InputMaybe<Scalars['ZonedDateTime']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<User>;
};

export type RemindFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID'];
};

export type RemoveFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID'];
};

export type RepairClientUsersPayload = {
  __typename?: 'RepairClientUsersPayload';
  processed: Scalars['Int'];
  remaining: Scalars['Int'];
};

export type ReportUserInput = {
  chatRoomId?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  surveyResponseId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type RequiredLocalDateRange = {
  end: Scalars['LocalDate'];
  start: Scalars['LocalDate'];
};

export type ResetProgressPayload = {
  __typename?: 'ResetProgressPayload';
  contentEdge?: Maybe<ContentEdge>;
  section?: Maybe<ContentSection>;
};

export type RootChallengesConnection = {
  __typename?: 'RootChallengesConnection';
  count: Scalars['Int'];
  edges: Array<Maybe<ChallengeEdge>>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type RootChatRoomsConnection = Connection & {
  __typename?: 'RootChatRoomsConnection';
  count: Scalars['Int'];
  edges: Array<RootChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
};

export type RootChatRoomsEdge = Edge & {
  __typename?: 'RootChatRoomsEdge';
  cursor: Scalars['String'];
  node: ChatRoom;
};

export type RootClientsConnection = Connection & {
  __typename?: 'RootClientsConnection';
  count: Scalars['Int'];
  edges: Array<RootClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type RootClientsEdge = Edge & {
  __typename?: 'RootClientsEdge';
  cursor: Scalars['String'];
  node: Client;
};

export type RootEligibleEmailAddressesConnection = Connection & {
  __typename?: 'RootEligibleEmailAddressesConnection';
  count: Scalars['Int'];
  edges: Array<RootEligibleEmailAddressesEdge>;
  nodes: Array<EligibleEmailAddress>;
  pageInfo: PageInfo;
};

export type RootEligibleEmailAddressesEdge = Edge & {
  __typename?: 'RootEligibleEmailAddressesEdge';
  cursor: Scalars['String'];
  node: EligibleEmailAddress;
};

export type RootMboClassEdge = Edge & {
  __typename?: 'RootMboClassEdge';
  cursor: Scalars['String'];
  node: MboClass;
};

export type RootTeamsConnection = Connection & {
  __typename?: 'RootTeamsConnection';
  count: Scalars['Int'];
  edges: Array<RootTeamsEdge>;
  nodes: Array<Team>;
  pageInfo: PageInfo;
};

export type RootTeamsEdge = Edge & {
  __typename?: 'RootTeamsEdge';
  cursor: Scalars['String'];
  node: Team;
};

export type RootUsersConnection = Connection & {
  __typename?: 'RootUsersConnection';
  count: Scalars['Int'];
  edges: Array<RootUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type RootUsersEdge = Edge & {
  __typename?: 'RootUsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export type SsoIdentity = {
  __typename?: 'SSOIdentity';
  connection?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

export type SavedPayload = {
  __typename?: 'SavedPayload';
  content: Content;
  saved: Scalars['Boolean'];
};

export type SessionCreditsInput = {
  count: Scalars['Int'];
  rowVersion?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type SessionCreditsPayload = {
  __typename?: 'SessionCreditsPayload';
  count: Scalars['Int'];
  rowVersion: Scalars['Int'];
  userId: Scalars['ID'];
};

export type SetCurrentTeamPayload = {
  __typename?: 'SetCurrentTeamPayload';
  team: Team;
  user: User;
};

export type SetMinBuildNumberInput = {
  app: App;
  buildNumber: Scalars['Int'];
  os: Os;
};

export type SetModalStatus = {
  id: Scalars['ID'];
  modalSeen: Scalars['Boolean'];
};

export type SetNotificationOptionsPayload = {
  __typename?: 'SetNotificationOptionsPayload';
  notificationOptions: Array<NotificationOption>;
};

export type Share = {
  __typename?: 'Share';
  message?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SignedUrlInput = {
  contentType: Scalars['String'];
  prefix: Prefix;
};

export type SignedUrlPayload = {
  __typename?: 'SignedUrlPayload';
  key: Scalars['String'];
  photo: Photo;
  signedUrl: Scalars['String'];
};

export type SimpleContentConnection = {
  count: Scalars['Int'];
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type SimpleContentEdge = {
  cursor: Scalars['String'];
  node: Content;
};

export type SleepActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'SleepActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID'];
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    sleepEfficiency?: Maybe<Scalars['Float']>;
    sleepOverview: SleepOverview;
    source?: Maybe<Scalars['JSONObject']>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type SleepOverview = {
  __typename?: 'SleepOverview';
  asleep: Scalars['Int'];
  awake: Scalars['Int'];
  inbed: Scalars['Int'];
};

export type StartPracticePayload = {
  __typename?: 'StartPracticePayload';
  assignedPractice: AssignedPractice;
};

export type StartProgramPayload = {
  __typename?: 'StartProgramPayload';
  assignedProgram: AssignedProgram;
};

export type StringAnswer = {
  __typename?: 'StringAnswer';
  stringValue: Scalars['String'];
};

export type Studio = Node & {
  __typename?: 'Studio';
  client: Client;
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  isCentral: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  locations: CoachLocationsConnection;
  mboAccessToken?: Maybe<Scalars['String']>;
  mboServiceId: Scalars['String'];
  mboStudioId: Scalars['String'];
  name: Scalars['String'];
  requiredFields: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type StudioLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioEdge = Edge & {
  __typename?: 'StudioEdge';
  cursor: Scalars['String'];
  node: Studio;
};

export type StudiosConnection = Connection & {
  __typename?: 'StudiosConnection';
  count: Scalars['Int'];
  edges: Array<StudioEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type StudiosFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatRoomUpdate: ChatRoom;
  newMessages: NewMessagePayload;
  newTotalUnreadMessageCount: Scalars['Int'];
  totalUnreadMessageCount: TotalUnreadMessageCountPayload;
};

export type SubscriptionChatRoomUpdateArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionNewMessagesArgs = {
  chatId?: InputMaybe<Scalars['ID']>;
};

export type SubscriptionNewTotalUnreadMessageCountArgs = {
  apps?: InputMaybe<Array<App>>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
};

export type SubscriptionTotalUnreadMessageCountArgs = {
  fromCoachedUsers?: InputMaybe<Scalars['Boolean']>;
};

export enum SupportRequestType {
  DataAccess = 'DATA_ACCESS',
  DataDeletion = 'DATA_DELETION',
}

export type Survey = Node & {
  __typename?: 'Survey';
  createdAt: Scalars['ZonedDateTime'];
  id: Scalars['ID'];
  responses: Array<SurveyResponse>;
  scope?: Maybe<Scalars['String']>;
  surveyIdentifier: Scalars['String'];
  surveyName?: Maybe<Scalars['String']>;
  template?: Maybe<SurveyTemplate>;
  user: User;
};

export type SurveyFilter = {
  surveyGroup?: InputMaybe<SurveyGroup>;
  surveyIdentifier?: InputMaybe<Scalars['String']>;
};

export enum SurveyGroup {
  Assessment = 'assessment',
  Basics = 'basics',
  Motivations = 'motivations',
}

export type SurveyResponse = Node & {
  __typename?: 'SurveyResponse';
  id: Scalars['ID'];
  numericValue?: Maybe<Scalars['Float']>;
  question: Scalars['String'];
  questionIdentifier: Scalars['String'];
  response: Answer;
  subcategory?: Maybe<Scalars['String']>;
  survey: Survey;
  viewedAt?: Maybe<Scalars['ZonedDateTime']>;
};

export type SurveyResponseInput = {
  caption?: InputMaybe<Scalars['String']>;
  choices?: InputMaybe<Array<Scalars['String']>>;
  numberAnswer?: InputMaybe<Scalars['Float']>;
  photoId?: InputMaybe<Scalars['String']>;
  question: Scalars['String'];
  questionIdentifier: Scalars['String'];
  scores?: InputMaybe<Array<Scalars['Int']>>;
  selectedChoices?: InputMaybe<Array<Scalars['Int']>>;
  stringAnswer?: InputMaybe<Scalars['String']>;
};

export type SurveyResponsePayload = {
  __typename?: 'SurveyResponsePayload';
  surveyResponse: SurveyResponse;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<Question>;
};

export type SyncEligibleEmailAddressInput = {
  clientId: Scalars['String'];
  email: Scalars['String'];
  employeeId: Scalars['String'];
};

export type SyncMboMembershipsPayload = {
  __typename?: 'SyncMboMembershipsPayload';
  user: User;
};

export type SystemPreferences = {
  __typename?: 'SystemPreferences';
  minFitAndroidBuild: Scalars['Int'];
  minFitIosBuild: Scalars['Int'];
  minPerformAndroidBuild: Scalars['Int'];
  minPerformIosBuild: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
};

export type Team = Node & {
  __typename?: 'Team';
  chatRoom?: Maybe<ChatRoom>;
  coach?: Maybe<User>;
  currentWeek: Scalars['Int'];
  huddle?: Maybe<Huddle>;
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  programSlug?: Maybe<Scalars['String']>;
  programStartDate?: Maybe<Scalars['ZonedDateTime']>;
  users: TeamUsersConnection;
};

export type TeamHuddleArgs = {
  timeZone?: InputMaybe<Scalars['ZoneId']>;
};

export type TeamUsersConnection = Connection & {
  __typename?: 'TeamUsersConnection';
  count: Scalars['Int'];
  edges: Array<TeamUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type TeamUsersEdge = Edge & {
  __typename?: 'TeamUsersEdge';
  cursor: Scalars['String'];
  node: User;
  programContent?: Maybe<ContentSection>;
  publicSurveyResponses?: Maybe<UserSurveyResponsesConnection>;
};

export type TeamUsersEdgeProgramContentArgs = {
  sectionName: Scalars['String'];
  week?: InputMaybe<Scalars['Int']>;
};

export type TeamUsersEdgePublicSurveyResponsesArgs = {
  filters?: InputMaybe<PublicSurveyResponseFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type TeamsFilter = {
  currentWeek?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TimeBasedActivity = {
  duration?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['ZonedDateTime']>;
  startTime?: Maybe<Scalars['ZonedDateTime']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  type: Scalars['String'];
};

export enum TokenPlatform {
  Mobile = 'mobile',
  Web = 'web',
}

export type TotalUnreadMessageCountPayload = {
  __typename?: 'TotalUnreadMessageCountPayload';
  count: Scalars['Int'];
};

export type TouchPointFilter = {
  dueRange?: InputMaybe<DateRange>;
  includeCompletedAfter?: InputMaybe<Scalars['ZonedDateTime']>;
  status?: InputMaybe<Array<CoachTouchPointStatus>>;
};

export type TouchPointsOrderBy = {
  direction: OrderDirection;
  order: CoachTouchPointOrder;
};

export type TriggerIterablePushPayload = {
  __typename?: 'TriggerIterablePushPayload';
  success: Scalars['Boolean'];
};

export type UnforgetUserPayload = {
  __typename?: 'UnforgetUserPayload';
  userId: Scalars['ID'];
};

export type UnitsInput = {
  unitsPreference: UnitsPreference;
};

export type UnitsPayload = {
  __typename?: 'UnitsPayload';
  user: User;
};

export enum UnitsPreference {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  addressLine3?: InputMaybe<Scalars['String']>;
  addressLine4?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  localityOrCity?: InputMaybe<Scalars['String']>;
  postalOrZipCode?: InputMaybe<Scalars['String']>;
  stateOrDistrictOrProvince?: InputMaybe<Scalars['String']>;
};

export type UpdateAvatarInput = {
  avatarId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateAvatarPayload = {
  __typename?: 'UpdateAvatarPayload';
  user: User;
};

export type UpdateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']>;
  badgeId?: InputMaybe<Scalars['String']>;
  challengeType?: InputMaybe<ChallengeType>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  clientSpecific?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['LocalDate']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  goal?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  qualifyingGenres?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  subtitle?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<UpdateChallengeTeamInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateChallengeTeamInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type UpdateChatRoomInput = {
  app?: InputMaybe<App>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  domainIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  logoId?: InputMaybe<Scalars['String']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']>;
  ssoConnection?: InputMaybe<Scalars['String']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']>;
  usesHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']>;
  usesPersonalTraining?: InputMaybe<Scalars['Boolean']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type UpdateCoachCronofyTimezoneInput = {
  coachId?: InputMaybe<Scalars['String']>;
  timezone: Scalars['ZoneId'];
};

export type UpdateCoachCronofyTimezoneResponse = {
  __typename?: 'UpdateCoachCronofyTimezoneResponse';
  user: User;
};

export type UpdateCoachDataInput = {
  availableForMemberAssignment?: InputMaybe<Scalars['Boolean']>;
  bio?: InputMaybe<Scalars['String']>;
  bodyPhotoId?: InputMaybe<Scalars['String']>;
  calendlyUrl?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  coachingStyle?: InputMaybe<CoachingStyle>;
  dailySessionLimit?: InputMaybe<Scalars['Int']>;
  degrees?: InputMaybe<Array<Scalars['String']>>;
  enableCalendarBuffer?: InputMaybe<Scalars['Boolean']>;
  headshotPhotoId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  inOfficeDays?: InputMaybe<Array<Scalars['Int']>>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  mboStaffId?: InputMaybe<Scalars['String']>;
  passions?: InputMaybe<Array<Scalars['String']>>;
  supportedCountries?: InputMaybe<Array<Scalars['String']>>;
  supportedGoals?: InputMaybe<Array<UserGoalType>>;
  titles?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<CoachType>;
};

export type UpdateCoachDataPayload = {
  __typename?: 'UpdateCoachDataPayload';
  user: User;
};

export type UpdateCoachLocationInput = {
  id: Scalars['ID'];
  isHeadquarters?: InputMaybe<Scalars['Boolean']>;
  mboLocationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  studioId: Scalars['String'];
};

export type UpdateCoachTouchPointInput = {
  description?: InputMaybe<Scalars['String']>;
  due?: InputMaybe<Scalars['ZonedDateTime']>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  memberId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateDomainInput = {
  clientId?: InputMaybe<Scalars['String']>;
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateEmergencyContactInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateMboBookingsPayload = {
  __typename?: 'UpdateMboBookingsPayload';
  remainingBookings: Scalars['Int'];
};

export type UpdateNoteInput = {
  content: Scalars['String'];
  memberId: Scalars['ID'];
};

export type UpdateNotificationOptionInput = {
  app?: InputMaybe<App>;
  description?: InputMaybe<Scalars['String']>;
  featureFlag?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  iterableId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planId: Scalars['ID'];
  planItems?: InputMaybe<Array<UpdatePlanItemInput>>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export type UpdatePlanItemInput = {
  component?: InputMaybe<Scalars['String']>;
  contentId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['LocalDate']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PlanItemType>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: Plan;
};

export type UpdatePracticePayload = {
  __typename?: 'UpdatePracticePayload';
  assignedPractice: AssignedPractice;
};

export type UpdateProfileJsonInput = {
  profileJson?: InputMaybe<Scalars['JSONObject']>;
};

export type UpdateProfileJsonPayload = {
  __typename?: 'UpdateProfileJsonPayload';
  user: User;
};

export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  assignedProgram: AssignedProgram;
};

export type UpdateStudioInput = {
  clientId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isCentral?: InputMaybe<Scalars['Boolean']>;
  isVirtual?: InputMaybe<Scalars['Boolean']>;
  mboServiceId?: InputMaybe<Scalars['String']>;
  mboStudioId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamInput = {
  chatRoomApp?: InputMaybe<App>;
  chatRoomName?: InputMaybe<Scalars['String']>;
  coachId?: InputMaybe<Scalars['String']>;
  createCoachChats?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  programSlug?: InputMaybe<Scalars['String']>;
  programStartDate?: InputMaybe<Scalars['ZonedDateTime']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateUserGoalInput = {
  coachNote?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  type: UserGoalType;
};

export type UpdateUserGoalPayload = {
  __typename?: 'UpdateUserGoalPayload';
  user: User;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<UpdateAddressInput>;
  birthday?: InputMaybe<Scalars['LocalDate']>;
  clientRelationship?: InputMaybe<UserClientRelationship>;
  countryCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emergencyContact?: InputMaybe<UpdateEmergencyContactInput>;
  firstName?: InputMaybe<Scalars['String']>;
  fitClientId?: InputMaybe<Scalars['ID']>;
  hasHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isConcierge?: InputMaybe<Scalars['Boolean']>;
  isMegaAdmin?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  isReadOnlyAdmin?: InputMaybe<Scalars['Boolean']>;
  isTestUser?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  officeLocation?: InputMaybe<Scalars['ID']>;
  pronoun?: InputMaybe<Scalars['String']>;
  registered?: InputMaybe<Scalars['Boolean']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']>;
  workingStyle?: InputMaybe<WorkingStyle>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Boolean'];
  activity: ActivityConnection;
  activityAggregates: ActivityAggregates;
  age?: Maybe<Scalars['Int']>;
  /** @deprecated Performs poorly and doesn't serve our use cases well. Prefer the app field on a chat or other object which is actually related to the interaction you're interested in. */
  app: App;
  assessmentSurveys: UserAssessmentSurveysConnection;
  assignedPrograms: UserAssignedProgramsConnection;
  auditLogs: AuditLogsConnection;
  authHistory: UserAuthHistoryConnection;
  availableClasses: UserAvailableClassesConnection;
  avatar?: Maybe<Photo>;
  birthday?: Maybe<Scalars['LocalDate']>;
  blockedUsers: UserBlockedUsersConnection;
  bookedClasses: UserBookingsConnection;
  canShareData: Scalars['Boolean'];
  challenges: UserChallengesConnection;
  chatRooms: UserChatRoomsConnection;
  clientRelationship?: Maybe<UserClientRelationship>;
  /** @deprecated use fitClient instead */
  clients: UserClientsConnection;
  coachData?: Maybe<CoachData>;
  coachSessionCredits?: Maybe<CoachSessionCredits>;
  coachedTeams: UserTeamsConnection;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['ZonedDateTime'];
  currentCustomPlan?: Maybe<Plan>;
  currentPractice?: Maybe<AssignedPractice>;
  currentProgram?: Maybe<AssignedProgram>;
  currentTeam?: Maybe<Team>;
  customPlans: UserPlansConnection;
  customPlansActiveDays: Array<ActiveCustomPlanDay>;
  devices: Array<Device>;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  email: Scalars['String'];
  emergencyContactDetails?: Maybe<EmergencyContactDetails>;
  familyInvitations: Array<EligibleEmailAddress>;
  firstName?: Maybe<Scalars['String']>;
  fitClient?: Maybe<Client>;
  fitCoach?: Maybe<User>;
  fitCoachChat?: Maybe<ChatRoom>;
  goal?: Maybe<UserGoal>;
  hasHighPerformanceCoaching: Scalars['Boolean'];
  hasInPersonMemberships: Scalars['Boolean'];
  homeCollection: ContentCollection;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isBlocked: Scalars['Boolean'];
  isCoach: Scalars['Boolean'];
  isConcierge: Scalars['Boolean'];
  isFamilyMember: Scalars['Boolean'];
  isMegaAdmin: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  isReadOnlyAdmin: Scalars['Boolean'];
  isStarred: Scalars['Boolean'];
  isTestUser: Scalars['Boolean'];
  iterableToken: Token;
  lastActivity: LastActivity;
  lastName?: Maybe<Scalars['String']>;
  note?: Maybe<Note>;
  /** @deprecated Requirements changed to one-to-one relationship */
  notes: UserNotesConnection;
  notificationOptions: Array<NotificationOption>;
  officeLocation?: Maybe<CoachLocation>;
  practicePrograms: UserPracticeProgramsConnection;
  profileComplete: Scalars['Boolean'];
  profileJson?: Maybe<Scalars['JSONObject']>;
  pronouns?: Maybe<Scalars['String']>;
  registered: Scalars['Boolean'];
  savedContent: ContentSavedConnection;
  section?: Maybe<ContentSection>;
  sessions: UserCoachSessionsConnection;
  share?: Maybe<Share>;
  shareDataWithEmployer: Scalars['Boolean'];
  shareEEPData: Scalars['Boolean'];
  shouldSeeRateAppModal: Scalars['Boolean'];
  studios: UserStudiosConnection;
  surveys: UserSurveysConnection;
  teams: UserTeamsConnection;
  token?: Maybe<Scalars['JSONObject']>;
  unitsPreference: UnitsPreference;
  userActivity: UserActivityConnection;
  userActivityTrend: Array<UserActivitySummary>;
  wearableSamples: WearableSamplesConnection;
  wearableSamplesLastSyncedAt?: Maybe<Scalars['ZonedDateTime']>;
  /** @deprecated Removed welcome section */
  welcomeSectionDisplay: WelcomeSectionDisplay;
  workingStyle?: Maybe<WorkingStyle>;
};

export type UserActivityArgs = {
  filter?: InputMaybe<ActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivityAggregatesArgs = {
  dateRange?: InputMaybe<DateRange>;
};

export type UserAssessmentSurveysArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssignedProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAvailableClassesArgs = {
  filter: MboClassFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBlockedUsersArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserBookedClassesArgs = {
  filter: MboBookingsFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserChallengesArgs = {
  filter?: InputMaybe<ChallengesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserChatRoomsArgs = {
  filters?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type UserClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCoachedTeamsArgs = {
  filters?: InputMaybe<TeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansArgs = {
  filter?: InputMaybe<CustomPlansFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansActiveDaysArgs = {
  filter: CustomPlanActiveDaysFilter;
};

export type UserHomeCollectionArgs = {
  week?: InputMaybe<Scalars['Int']>;
};

export type UserIterableTokenArgs = {
  tokenType?: InputMaybe<TokenPlatform>;
};

export type UserNotesArgs = {
  filter?: InputMaybe<UserNotesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserNotificationOptionsArgs = {
  app: App;
};

export type UserPracticeProgramsArgs = {
  filter?: InputMaybe<PracticeProgramFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSavedContentArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserSectionArgs = {
  id: Scalars['String'];
};

export type UserSessionsArgs = {
  dateFilter?: InputMaybe<Array<DateFilter>>;
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type UserStudiosArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserSurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserUserActivityArgs = {
  filter?: InputMaybe<UserActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserWearableSamplesArgs = {
  filter?: InputMaybe<WearableSamplesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  activity?: Maybe<Activity>;
  attribution?: Maybe<ActivityAttribution>;
  attributionTitle?: Maybe<Scalars['String']>;
  attributionType: UserActivityAttributionType;
  distance?: Maybe<Distance>;
  duration?: Maybe<Scalars['Int']>;
  endTime: Scalars['ZonedDateTime'];
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Use SleepActivity.sleepEfficiency instead */
  sleepEfficiency?: Maybe<Scalars['Float']>;
  source?: Maybe<ActivitySource>;
  startTime: Scalars['ZonedDateTime'];
  title: Scalars['String'];
  type: UserActivityType;
};

export enum UserActivityAttributionType {
  Assessment = 'Assessment',
  Classes = 'Classes',
  Monitoring = 'Monitoring',
  Other = 'Other',
  Practice = 'Practice',
  Program = 'Program',
  Sleep = 'Sleep',
}

export type UserActivityConnection = Connection & {
  __typename?: 'UserActivityConnection';
  count: Scalars['Int'];
  edges: Array<UserActivityEdge>;
  nodes: Array<UserActivity>;
  pageInfo: PageInfo;
};

export type UserActivityEdge = Edge & {
  __typename?: 'UserActivityEdge';
  cursor: Scalars['String'];
  node: UserActivity;
};

export type UserActivityFilter = {
  attributionType?: InputMaybe<Array<InputMaybe<UserActivityAttributionType>>>;
  type?: InputMaybe<Array<InputMaybe<UserActivityType>>>;
};

export type UserActivitySummary = {
  __typename?: 'UserActivitySummary';
  classes: Scalars['Int'];
  date: Scalars['LocalDate'];
  other: Scalars['Int'];
  practice: Scalars['Int'];
  program: Scalars['Int'];
};

export enum UserActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Assessment = 'Assessment',
  Audio = 'Audio',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  PracticeSelection = 'PracticeSelection',
  ProgramSelection = 'ProgramSelection',
  Video = 'Video',
  Virtual = 'Virtual',
  Vod = 'Vod',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type UserAssessmentAssignmentEdge = Edge & {
  __typename?: 'UserAssessmentAssignmentEdge';
  cursor: Scalars['String'];
  node: AssessmentAssignment;
};

export type UserAssessmentAssignmentsConnection = Connection & {
  __typename?: 'UserAssessmentAssignmentsConnection';
  count: Scalars['Int'];
  edges: Array<UserAssessmentAssignmentEdge>;
  nodes: Array<AssessmentAssignment>;
  pageInfo: PageInfo;
};

export type UserAssessmentAssignmentsFilter = {
  completed?: InputMaybe<Scalars['Boolean']>;
};

export type UserAssessmentSurvey = Node & {
  __typename?: 'UserAssessmentSurvey';
  assignments: UserAssessmentAssignmentsConnection;
  id: Scalars['ID'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   */
  isMemberFacing: Scalars['Boolean'];
  mnmrType?: Maybe<Scalars['String']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']>;
};

export type UserAssessmentSurveyAssignmentsArgs = {
  filter?: InputMaybe<UserAssessmentAssignmentsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssessmentSurveyEdge = Edge & {
  __typename?: 'UserAssessmentSurveyEdge';
  cursor: Scalars['String'];
  node: UserAssessmentSurvey;
};

export type UserAssessmentSurveysConnection = Connection & {
  __typename?: 'UserAssessmentSurveysConnection';
  count: Scalars['Int'];
  edges: Array<UserAssessmentSurveyEdge>;
  nodes: Array<UserAssessmentSurvey>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsConnection = Connection & {
  __typename?: 'UserAssignedProgramsConnection';
  count: Scalars['Int'];
  edges: Array<UserAssignedProgramsEdge>;
  nodes: Array<AssignedProgram>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsEdge = Edge & {
  __typename?: 'UserAssignedProgramsEdge';
  cursor: Scalars['String'];
  node: AssignedProgram;
};

export type UserAuthHistory = Node & {
  __typename?: 'UserAuthHistory';
  challengeResponses: Array<Scalars['String']>;
  eventDate?: Maybe<Scalars['ZonedDateTime']>;
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UserAuthHistoryConnection = Connection & {
  __typename?: 'UserAuthHistoryConnection';
  count: Scalars['Int'];
  edges: Array<UserAuthHistoryEdge>;
  nodes: Array<UserAuthHistory>;
  pageInfo: PageInfo;
};

export type UserAuthHistoryEdge = Edge & {
  __typename?: 'UserAuthHistoryEdge';
  cursor: Scalars['String'];
  node: UserAuthHistory;
};

export type UserAvailabilityConnection = Connection & {
  __typename?: 'UserAvailabilityConnection';
  count: Scalars['Int'];
  edges: Array<UserAvailabilityEdge>;
  nodes: Array<Calendar>;
  pageInfo: PageInfo;
};

export type UserAvailabilityEdge = Edge & {
  __typename?: 'UserAvailabilityEdge';
  cursor: Scalars['String'];
  node: Calendar;
};

export type UserAvailableClassesConnection = Connection & {
  __typename?: 'UserAvailableClassesConnection';
  count: Scalars['Int'];
  edges: Array<UserAvailableClassesEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserAvailableClassesEdge = Edge & {
  __typename?: 'UserAvailableClassesEdge';
  cursor: Scalars['String'];
  node: MboClass;
};

export type UserBlockedUsersConnection = Connection & {
  __typename?: 'UserBlockedUsersConnection';
  count: Scalars['Int'];
  edges: Array<UserBlockedUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type UserBlockedUsersEdge = Edge & {
  __typename?: 'UserBlockedUsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserBookingsConnection = Connection & {
  __typename?: 'UserBookingsConnection';
  count: Scalars['Int'];
  edges: Array<UserBookingsEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserBookingsEdge = Edge & {
  __typename?: 'UserBookingsEdge';
  cursor: Scalars['String'];
  node: MboClass;
};

export type UserChallengesConnection = Connection & {
  __typename?: 'UserChallengesConnection';
  count: Scalars['Int'];
  edges: Array<UserChallengesEdge>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type UserChallengesEdge = Edge & {
  __typename?: 'UserChallengesEdge';
  cursor: Scalars['String'];
  node: Challenge;
  place?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type UserChatRoomsConnection = Connection & {
  __typename?: 'UserChatRoomsConnection';
  count: Scalars['Int'];
  edges: Array<UserChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int'];
};

export type UserChatRoomsEdge = Edge & {
  __typename?: 'UserChatRoomsEdge';
  cursor: Scalars['String'];
  node: ChatRoom;
};

export enum UserClientRelationship {
  Dependent = 'Dependent',
  Employee = 'Employee',
}

export type UserClientsConnection = Connection & {
  __typename?: 'UserClientsConnection';
  count: Scalars['Int'];
  edges: Array<UserClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type UserClientsEdge = Edge & {
  __typename?: 'UserClientsEdge';
  cursor: Scalars['String'];
  node: Client;
};

export type UserCoachSessionsConnection = Connection & {
  __typename?: 'UserCoachSessionsConnection';
  count: Scalars['Int'];
  edges: Array<UserCoachSessionsEdge>;
  nodes: Array<CoachSession>;
  pageInfo: PageInfo;
};

export type UserCoachSessionsEdge = Edge & {
  __typename?: 'UserCoachSessionsEdge';
  cursor: Scalars['String'];
  node: CoachSession;
};

export type UserGoal = {
  __typename?: 'UserGoal';
  coachNote?: Maybe<Scalars['String']>;
  coachNoteUpdatedBy?: Maybe<User>;
  createdAt: Scalars['ZonedDateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  type: UserGoalType;
  updatedAt: Scalars['ZonedDateTime'];
};

export enum UserGoalType {
  Appearance = 'Appearance',
  EmotionalWellbeing = 'EmotionalWellbeing',
  EnergyLevels = 'EnergyLevels',
  Health = 'Health',
  /** @deprecated Not used */
  NotSure = 'NotSure',
  PainManagement = 'PainManagement',
  /** @deprecated Use 'PainManagement' instead */
  PainReduction = 'PainReduction',
  PhysicalPerformance = 'PhysicalPerformance',
  /** @deprecated Use 'SocialWellbeing' instead */
  Relationships = 'Relationships',
  SocialWellbeing = 'SocialWellbeing',
  /** @deprecated Use 'PhysicalPerformance' instead */
  ToneUp = 'ToneUp',
  /** @deprecated Not used */
  TryingNewThings = 'TryingNewThings',
  WorkPerformance = 'WorkPerformance',
}

export type UserNotesConnection = Connection & {
  __typename?: 'UserNotesConnection';
  count: Scalars['Int'];
  edges: Array<UserNotesEdge>;
  nodes: Array<Note>;
  pageInfo: PageInfo;
};

export type UserNotesEdge = Edge & {
  __typename?: 'UserNotesEdge';
  cursor: Scalars['String'];
  node: Note;
};

export type UserNotesFilter = {
  coachId?: InputMaybe<Scalars['ID']>;
};

export type UserPlansConnection = Connection & {
  __typename?: 'UserPlansConnection';
  count: Scalars['Int'];
  edges: Array<UserPlansEdge>;
  nodes: Array<Plan>;
  pageInfo: PageInfo;
};

export type UserPlansEdge = Edge & {
  __typename?: 'UserPlansEdge';
  cursor: Scalars['String'];
  node: Plan;
};

export type UserPracticeProgramEdge = Edge & {
  __typename?: 'UserPracticeProgramEdge';
  cursor: Scalars['String'];
  node: PracticeProgram;
};

export type UserPracticeProgramsConnection = Connection & {
  __typename?: 'UserPracticeProgramsConnection';
  count: Scalars['Int'];
  edges: Array<UserPracticeProgramEdge>;
  nodes: Array<PracticeProgram>;
  pageInfo: PageInfo;
};

export type UserStudiosConnection = Connection & {
  __typename?: 'UserStudiosConnection';
  count: Scalars['Int'];
  edges: Array<UserStudiosEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type UserStudiosEdge = Edge & {
  __typename?: 'UserStudiosEdge';
  cursor: Scalars['String'];
  node: Studio;
};

export type UserSurveyResponsesConnection = Connection & {
  __typename?: 'UserSurveyResponsesConnection';
  count: Scalars['Int'];
  edges: Array<UserSurveyResponsesEdge>;
  nodes: Array<SurveyResponse>;
  pageInfo: PageInfo;
};

export type UserSurveyResponsesEdge = Edge & {
  __typename?: 'UserSurveyResponsesEdge';
  cursor: Scalars['String'];
  node: SurveyResponse;
};

export type UserSurveysConnection = Connection & {
  __typename?: 'UserSurveysConnection';
  count: Scalars['Int'];
  edges: Array<UserSurveysEdge>;
  nodes: Array<Survey>;
  pageInfo: PageInfo;
};

export type UserSurveysEdge = Edge & {
  __typename?: 'UserSurveysEdge';
  cursor: Scalars['String'];
  node: Survey;
};

export type UserTeamsConnection = Connection & {
  __typename?: 'UserTeamsConnection';
  count: Scalars['Int'];
  edges: Array<UserTeamsEdge>;
  nodes: Array<Team>;
  pageInfo: PageInfo;
};

export type UserTeamsEdge = Edge & {
  __typename?: 'UserTeamsEdge';
  cursor: Scalars['String'];
  node: Team;
};

export type UsersFilter = {
  clientId?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isCoach?: InputMaybe<Scalars['Boolean']>;
  isTestUser?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Video = Content &
  Node & {
    __typename?: 'Video';
    author?: Maybe<Scalars['String']>;
    brand?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    equipment: Array<Scalars['String']>;
    /** @deprecated Use subtitle now */
    eyebrow?: Maybe<Scalars['String']>;
    feedback?: Maybe<FeedbackKind>;
    /** @deprecated use contentGenre */
    formattedGenre?: Maybe<Scalars['String']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    intensity?: Maybe<Scalars['String']>;
    isPublished: Scalars['Boolean'];
    kind?: Maybe<Scalars['String']>;
    level?: Maybe<Scalars['String']>;
    mediaType?: Maybe<Scalars['String']>;
    mnmrType?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    readingTime?: Maybe<Scalars['String']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']>;
    subGenre?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
    /** @deprecated No longer supported */
    thumbnail?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    trainingCommitment?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    /** @deprecated No longer supported */
    url?: Maybe<Scalars['String']>;
    video: Scalars['String'];
  };

export type VideoRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type VideoActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'VideoActivity';
    brand?: Maybe<Scalars['String']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']>;
    coachLastName?: Maybe<Scalars['String']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID'];
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    source?: Maybe<Video>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'WearableActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']>;
    endTime?: Maybe<Scalars['ZonedDateTime']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID'];
    overlappingActivity?: Maybe<Activity>;
    program?: Maybe<Program>;
    progress: Scalars['Float'];
    source?: Maybe<Scalars['JSONObject']>;
    startTime?: Maybe<Scalars['ZonedDateTime']>;
    title: Scalars['String'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableSample = Node & {
  __typename?: 'WearableSample';
  id: Scalars['ID'];
  platform: Os;
  platformVersion: Scalars['String'];
  sdk: WearableSdk;
  startDate: Scalars['ZonedDateTime'];
};

export type WearableSampleEdge = Edge & {
  __typename?: 'WearableSampleEdge';
  cursor: Scalars['String'];
  node: WearableSample;
};

export type WearableSamplesConnection = Connection & {
  __typename?: 'WearableSamplesConnection';
  count: Scalars['Int'];
  edges: Array<WearableSampleEdge>;
  nodes: Array<WearableSample>;
  pageInfo: PageInfo;
};

export type WearableSamplesFilter = {
  platform?: InputMaybe<Os>;
  startDateRange?: InputMaybe<DateRange>;
};

export type WearableSamplesInput = {
  build: Scalars['String'];
  platform: Os;
  platformVersion: Scalars['String'];
  samples: Array<Scalars['JSONObject']>;
  sdk: WearableSdk;
};

export enum WearableSdk {
  GoogleFit = 'GoogleFit',
  HealthConnect = 'HealthConnect',
  HealthKit = 'HealthKit',
}

export enum WelcomeSectionDisplay {
  Default = 'default',
  Hidden = 'hidden',
  ShowAlways = 'showAlways',
}

export enum WorkingStyle {
  Hybrid = 'hybrid',
  Office = 'office',
  Remote = 'remote',
}

export type ZonedDateTimeRange = {
  __typename?: 'ZonedDateTimeRange';
  end?: Maybe<Scalars['ZonedDateTime']>;
  start?: Maybe<Scalars['ZonedDateTime']>;
};

export type SelfQueryVariables = Exact<{ [key: string]: never }>;

export type SelfQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type UserDetailsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type PhotoBasicsFragment = {
  __typename?: 'Photo';
  id: string;
  basePath: string;
  preview?: string | null;
};

export const PhotoBasicsFragmentDoc = gql`
  fragment PhotoBasics on Photo {
    id
    basePath
    preview
  }
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    id
    firstName
    lastName
    avatar {
      ...PhotoBasics
    }
  }
  ${PhotoBasicsFragmentDoc}
`;
export const SelfDocument = gql`
  query self {
    self {
      ...UserDetails
    }
  }
  ${UserDetailsFragmentDoc}
`;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
}
export function useSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export function useSelfSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfSuspenseQueryHookResult = ReturnType<
  typeof useSelfSuspenseQuery
>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
