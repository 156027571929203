import React from 'react';

import Loadable from '@loadable/component';
import { Helmet } from 'react-helmet';

import '../styles/playground.css';

import NavBar from './NavBar';

const Playground = Loadable(() => import('./Playground'));

const PlaygroundPage = () => (
  <>
    <Helmet>
      <body className="prince-playground" />
    </Helmet>
    <NavBar />
    <Playground />
  </>
);

export default PlaygroundPage;
