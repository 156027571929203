import { useLocation } from '@reach/router';
import { useAuthContext } from 'amplify-auth-hooks';
import { navigate } from 'gatsby';

const useRequireAuth = () => {
  const location = useLocation();
  const { authState } = useAuthContext() ?? {};

  // `navigate` below relies on the window context
  if (typeof window === 'undefined') {
    return false;
  }

  if (authState !== 'signedIn' && location?.pathname !== `/app/login`) {
    navigate('/app/login');

    return false;
  }

  return true;
};

export default useRequireAuth;
