import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { useSelfQuery } from '../graphql/types';
import useRequireAuth from '../hooks/useRequireAuth';

import AuthLayout from './AuthLayout';

type Props<TParams = {}> = RouteComponentProps<TParams> & {
  component: React.ComponentType<RouteComponentProps<TParams>>;
};

const Private: React.FC<Props> = ({
  component: Component,
  location,
  ...rest
}) => {
  const { loading } = useSelfQuery({ fetchPolicy: 'cache-first' });
  const authenticated = useRequireAuth();

  if (loading || !authenticated) {
    // Prevents flash of home screen
    return <AuthLayout />;
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return <Component location={location} {...rest} />;
};

export default Private;
