import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'gatsby';

const useLogout = () => {
  const client = useApolloClient();
  const logout = useCallback(async () => {
    await Auth.signOut();
    await client.clearStore();
    navigate('/app/login');
  }, []);

  return logout;
};

export default useLogout;
