import React from 'react';

import {
  AvatarProps,
  Avatar as MUIAvatar,
  Typography,
} from '@material-ui/core';

import type { UserDetailsFragment } from '../graphql/types';

export type PhotoUserFragment = Pick<
  UserDetailsFragment,
  'avatar' | 'firstName' | 'lastName'
>;
interface Props extends AvatarProps {
  size: number;
  user?: PhotoUserFragment | null;
}

const Avatar: React.FC<Props> = ({ size, user, ...rest }) => {
  const { firstName, lastName, avatar: photo } = user ?? {};
  const adjustedSize = photo ? size + 2 : size;
  const preview = photo?.preview;
  const previewUrl = preview ? `url(${preview})` : undefined;
  const letterSize = size ? size / 2.5 : 16;

  const initials = `${firstName?.slice(0, 1) || ''}${
    lastName?.slice(0, 1) || ''
  }`;

  return (
    <MUIAvatar
      {...rest}
      style={{
        width: adjustedSize,
        height: adjustedSize,
        borderRadius: adjustedSize / 2,
        backgroundImage: previewUrl,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        borderWidth: 0,
        borderStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-testid="avatar"
    >
      {photo ? (
        <img
          alt={`${firstName} ${lastName}'s avatar`}
          src={`${photo?.basePath}/${photo?.id}/${adjustedSize}x${adjustedSize}.jpg`}
        />
      ) : (
        <Typography
          style={{ fontSize: letterSize, textTransform: 'uppercase' }}
        >
          {initials}
        </Typography>
      )}
    </MUIAvatar>
  );
};

export default Avatar;
