import React from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

interface Props {
  name: string;
}

const Errors: React.FC<Props> = ({ name }) => {
  const { palette } = useTheme();
  return (
    <ErrorMessage
      name={name}
      render={({ messages }) => {
        if (!messages) {
          return null;
        }

        return Object.entries(messages).map(([type, msg]) => (
          <Typography
            variant="subtitle1"
            style={{
              color: palette.error.main,
            }}
            key={type}
          >
            {msg}
          </Typography>
        ));
      }}
    />
  );
};

export default Errors;
