import React from 'react';

import { Redirect, Router } from '@reach/router';

import Layout from '../components/Layout';
import PlaygroundPage from '../components/PlaygroundPage';
import PrivateRoute from '../components/PrivateRoute';
import SignIn from '../components/SignIn';

const App = () => (
  <Layout>
    <Router basepath="/app/">
      <SignIn path="/login" />
      <PrivateRoute path="/" component={PlaygroundPage} />
      <Redirect noThrow from="*" to="/app/" />
    </Router>
  </Layout>
);

export default App;
