import React, { FC, MouseEvent, ReactNode, useState } from 'react';

import { Button, Menu, MenuItem } from '@material-ui/core';
import styled from 'styled-components';

export const FlyoutItem: FC<{
  onClick: () => void;
  children?: React.ReactNode;
  dataTestId?: string;
}> = ({ children, onClick, dataTestId }) => (
  <MenuItem style={{ width: 130 }} onClick={onClick} data-testid={dataTestId}>
    {children}
  </MenuItem>
);

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    top: 60px !important;
  }
`;

interface FlyoutProps {
  flyoutId: string;
  trigger: ReactNode;
  children?: ReactNode;
}

const Flyout: FC<FlyoutProps> = ({ flyoutId = '', trigger, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div data-testid="flyout">
      <Button
        aria-controls={`flyout-menu-${flyoutId}`}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {trigger}
      </Button>
      <StyledMenu
        id={`flyout-menu-${flyoutId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* This div is required for the forwardRef to work */}
        <div>{children}</div>
      </StyledMenu>
    </div>
  );
};

export default Flyout;
