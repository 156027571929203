import React from 'react';

import { Auth } from '@aws-amplify/auth';
import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { useAuthContext } from 'amplify-auth-hooks';
import { navigate } from 'gatsby';
import { FormProvider } from 'react-hook-form';

import useForm from '../../hooks/useForm';
import AuthLayout from '../AuthLayout';
import Errors from '../reusable/ErrorMessage';

const SignIn: React.FC<RouteComponentProps> = () => {
  const methods = useForm({ mode: 'onChange', criteriaMode: 'all' });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showPasswordInput, setShowPasswordInput] = React.useState(false);

  const cognito = useAuthContext();

  const { getValues, handleSubmit, register, setServerError, watch } = methods;
  watch();

  const cognitoPasswordLogin = React.useCallback(
    async (params: { email: string; password: string }) => {
      const response = await Auth.signIn({
        password: params.password,
        username: params.email,
      });

      cognito.handleStateChange('signedIn', response);
    },
    [cognito],
  );

  const signInWithPassword = async (email: string, password: string) => {
    try {
      await cognitoPasswordLogin({
        email,
        password,
      });
      navigate('/app/');
    } catch (e) {
      if (e.message.includes('Incorrect username or password.')) {
        setServerError('Incorrect email or password');
      } else {
        setServerError('Error signing in. Please contact Coach Hub support.');
      }
    }
  };

  const signIn = async () => {
    setIsSubmitting(true);
    const values = getValues();

    if (showPasswordInput) {
      signInWithPassword(values.email.trim(), values.password.trim());
      return;
    }

    try {
      const userCheckUrl = `${process.env.GATSBY_API_URL}/user-check`;
      const userCheck = await fetch(userCheckUrl, {
        method: 'POST',
        body: JSON.stringify({ email: values.email.trim() }),
      });

      const info = await userCheck.json();

      if (!info?.registrationEnabled) {
        setServerError('This domain or email address is not eligible.');
        setIsSubmitting(false);
        return;
      }

      if (info.isSSO) {
        await Auth.federatedSignIn({
          customProvider: info.identity?.provider,
          customState: JSON.stringify({
            email: values.email.trim(),
            connection: info.identity?.connection,
          }),
        });
      } else {
        setShowPasswordInput(true);
        setIsSubmitting(false);
      }
    } catch (err) {
      setServerError(err.message);
    }
  };

  return (
    <AuthLayout>
      <Paper
        elevation={2}
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 496,
          height: 496,
          borderRadius: 8,
          paddingTop: 48,
          paddingBottom: 48,
          paddingLeft: 60,
          paddingRight: 60,
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(signIn)();
        }}
      >
        <Typography
          variant="h3"
          style={{ textAlign: 'center', marginBottom: 48 }}
          data-testid="sign-in-header"
        >
          Sign In
        </Typography>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <FormProvider {...methods}>
          <TextField
            fullWidth
            autoFocus
            id="outlined-basic"
            label="Email address"
            variant="outlined"
            {...register('email')}
          />
          {showPasswordInput && (
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              style={{ marginTop: '16px' }}
              {...register('password')}
            />
          )}
          <Errors name="server" />
          <div style={{ flex: 1 }} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            style={{ padding: 12, borderRadius: 4, alignSelf: 'flex-end' }}
            disabled={isSubmitting || !getValues('email')}
          >
            {isSubmitting ? (
              <CircularProgress color="inherit" size={24.5} />
            ) : (
              'Sign In'
            )}
          </Button>
        </FormProvider>
      </Paper>
    </AuthLayout>
  );
};

export default SignIn;
