import React from 'react';

import { Typography } from '@material-ui/core';

import {
  BLACK2,
  DISABLED_GRAY,
  GRAY600,
  ICON_SECONDARY,
  WHITE,
} from '../../../theme';

const FONT_SHARP = 'sharp';
const FONT_READER = 'reader';

export enum TextKind {
  TitleXl = 'TitleXl',
  TitleL = 'TitleL',
  TitleM = 'TitleM',
  TitleS = 'TitleS',
  TitleXs = 'TitleXs',
  BodyL = 'BodyL',
  BodyM = 'BodyM',
  BodyS = 'BodyS',
  LabelL = 'LabelL',
  LabelM = 'LabelM',
  ButtonL = 'ButtonL',
  ButtonM = 'ButtonM',
}

export enum TextColor {
  Primary = 'Primary',
  Secondary = 'Secondary',
  White = 'White',
  DarkerGray = 'DarkerGray',
}

interface Props {
  kind?: keyof typeof TextKind;
  color?: keyof typeof TextColor;
  component?: React.ElementType;
  /** Try to avoid using this parameter. Ideally all typography variations are covered by the `kind` prop */
  style?: React.CSSProperties;
  children?: React.ReactNode;
  bold?: boolean;
  'data-testid'?: string;
}

const styleFrom = (
  kind: keyof typeof TextKind,
  color: keyof typeof TextColor,
  bold: boolean,
) => ({
  ...{
    TitleXl: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 40,
      lineHeight: 48 / 40,
    },
    TitleL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 30,
      lineHeight: 32 / 30,
    },
    TitleM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 24,
      lineHeight: 30 / 24,
    },
    TitleS: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 24 / 20,
    },
    TitleXs: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 20 / 16,
    },
    BodyL: {
      fontFamily: FONT_READER,
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 28 / 20,
    },
    BodyM: {
      fontFamily: FONT_READER,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 20 / 14,
    },
    BodyS: {
      fontFamily: FONT_READER,
      fontWeight: bold ? 600 : 400,
      fontSize: 12,
      lineHeight: 18 / 12,
    },
    LabelL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 18 / 14,
    },
    LabelM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 14 / 18,
      textTransform: 'uppercase' as const,
    },
    ButtonL: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 16 / 16,
    },
    ButtonM: {
      fontFamily: FONT_SHARP,
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 12 / 12,
    },
  }[kind],
  ...{
    color: {
      Primary: BLACK2,
      Secondary: ICON_SECONDARY,
      Disabled: DISABLED_GRAY,
      DarkerGray: GRAY600,
      White: WHITE,
    }[color],
  },
});

const Text: React.FC<Props> = ({
  kind = TextKind.BodyS,
  children,
  component = 'p',
  color = 'Primary',
  style = {},
  bold = false,
  'data-testid': testId,
}) => (
  <Typography
    style={{
      ...styleFrom(kind, color, bold),
      ...style,
    }}
    component={component}
    data-testid={testId}
  >
    {children}
  </Typography>
);

export default Text;
