import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar } from '@material-ui/core';
import { useMatch } from '@reach/router';
import styled from 'styled-components';

import ExosLogo from '../../assets/images/Exos-logomark.svg';
import { useSelfQuery } from '../../graphql/types';
import useLogout from '../../hooks/useLogout';
import { WHITE } from '../../theme';
import Avatar from '../Avatar';
import Flyout, { FlyoutItem } from '../Flyout';
import Text, { TextColor, TextKind } from '../reusable/Text';

const StyledNavBar = styled(AppBar)`
  position: relative;
  height: 52px;
  & .MuiToolbar-regular {
    height: 52px;
    min-height: 52px;
  }
  &.MuiAppBar-colorPrimary {
    background-color: black;
    color: white;
  }
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  &.MuiToolbar-gutters {
    padding: 0 16px;
  }
`;

const AvatarMenu = styled.div`
  display: flex;
  gap: 2px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const BackButton = styled((props) => (
  <button type="button" {...props}>
    {props.children}
  </button>
))`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${WHITE};
  cursor: pointer;
  display: flex;
  height: 40px;
`;

const BackArrow = styled((props) => (
  <FontAwesomeIcon icon={['fal', 'arrow-left']} {...props} />
))`
  height: 16px;
  margin-right: 8px;
  width: 16px;
`;

const Navigation = () => {
  const isMemberProfile = useMatch('/app/members/:memberId');

  const handleBackClick = () => {
    if (typeof window === 'undefined') {
      return;
    }
    window.history.back();
  };

  if (isMemberProfile) {
    return (
      <BackButton onClick={handleBackClick}>
        <BackArrow />
        <Text color="White" kind={TextKind.BodyM} style={{ lineHeight: 1 }}>
          Back
        </Text>
      </BackButton>
    );
  }

  return <img src={ExosLogo} alt="Exos Logo" width={30} height={30} />;
};

const NavBar = () => {
  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
  });

  const user = data?.self;
  const logout = useLogout();

  return (
    <StyledNavBar position="fixed" elevation={0}>
      <StyledToolbar>
        <Navigation />

        {user && user.firstName && (
          <AvatarMenu>
            <Text color={TextColor.White} kind={TextKind.BodyM}>
              {user.firstName}
            </Text>
            <Flyout
              flyoutId="navBar"
              trigger={<Avatar size={36} user={user} />}
            >
              <FlyoutItem onClick={logout} dataTestId="logout-button">
                Log out
              </FlyoutItem>
            </Flyout>
          </AvatarMenu>
        )}
      </StyledToolbar>
    </StyledNavBar>
  );
};

export default NavBar;
